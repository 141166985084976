import React, { useEffect, useRef, useState } from "react";
import PopupOptions from "./popupOptions";
import {
  getComonCodeForCompany,
  getFormatedDate,
} from "../../services/commonFunction";
import { useSelector } from "react-redux";
import PartialIcon from "../../assets/images/icons/Partial.svg";
import PostedIcon from "../../assets/images/icons/Posted.svg";
import CompletedIcon from "../../assets/images/icons/Completed.svg";
import AllotedIcon from "../../assets/images/icons/Alloted.svg";
import PendingIcon from "../../assets/images/icons/Pending.svg";
import RejectedIcon from "../../assets/images/icons/Rejected.svg";
import AcceptedIcon from "../../assets/images/icons/Accepted.svg";
import InprocessIcon from "../../assets/images/icons/Inprocess.svg";

import { Row, Col, CardTitle } from "react-bootstrap";
import { postDataFromApi } from "../../services/commonServices";
import { getJIsowandactivityApi } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { encryptDataForURL } from "../../utills/useCryptoUtils";
import PropTypes from "prop-types";

export const selectUser = (state) => state.user;

const RenderSubListSection = ({
  section,
  sectionIndex,
  actions,
  responseData,
  getAllListingData,
  formConfig,
  statusCounts,
  setIsRejectPopupOpen,
  setJRFCreationType,
  setIsPopupOpen,
  loadingTable,
  setIsOverlayLoader,
  moduleType,
  formData,
}) => {
  let user = useSelector(selectUser);
  const navigate = useNavigate();
  const session = useSelector((state) => state.session);

  user = session.user;

  const [popupIndex, setPopupIndex] = useState(-1);
  const [sortStates, setSortStates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(10);
  const [sizeofPage, setSizeOfPage] = useState(10);
  const [countPage, setCountPage] = useState(10);
  const [filteredAction, setFilteredAction] = useState(actions);
  const [subTableData, setSubTableData] = useState([]);

  const popupRef = useRef(null);
  useEffect(() => {
    setSortStates(Array(section.headers?.length).fill(false));
  }, [section]);

  useEffect(() => {
    if (moduleType == "jobinstruction") {
      getJIVesselDataData(formData[0]?.ji_id);
    }
  }, []);
  const getJIVesselDataData = async (ji_id) => {
    try {
      const bodyData = {
        ji_id: ji_id,
      };
      let res = await postDataFromApi(getJIsowandactivityApi, bodyData);
      if (res?.data?.status === 200 && res.data.data) {
        const responseData = res.data.data;
        const updatedFormData = { ...formData };
        let type = "scope_of_work";
        if (type === "scope_of_work") {
          setSubTableData(responseData[type]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClick = (index, fieldName) => {
    const newSortStates = Array(section.headers?.length).fill(false);
    newSortStates[index] = !sortStates[index];
    let sortType = newSortStates[index] ? "desc" : "asc";
    getAllListingData(currentPage, fieldName, sortType);
    setSortStates(newSortStates);
  };

  const handlePaginationButton = (pageNo) => {
    getAllListingData(pageNo);
    setCurrentPage(pageNo);
  };

  const createButton = (page, currentPage, handlePaginationButton) => {
    return (
      <button
        type="button"
        className={
          currentPage === page
            ? "btn btn-danger pagination-active"
            : "btn btn-danger"
        }
        key={"page-" + page}
        onClick={() => handlePaginationButton(page)}
      >
        {page}
      </button>
    );
  };

  const getPaginationNo = () => {
    const displayedPages = [];
    const maxPagesToShow = 7; // Limit visible page buttons to avoid clutter.

    const middle = Math.floor(maxPagesToShow / 2);
    const left = Math.max(1, currentPage - middle);
    const right = Math.min(totalPage, currentPage + middle);

    if (left > 1) {
      displayedPages.push(createButton(1, currentPage, handlePaginationButton));
      if (left > 2) {
        displayedPages.push(<button key={"extra-eclipse"}>...</button>);
      }
    }

    for (let i = Math.max(1, left); i <= Math.min(totalPage, right); i++) {
      displayedPages.push(createButton(i, currentPage, handlePaginationButton));
    }

    if (right < totalPage) {
      if (right < totalPage - 1) {
        displayedPages.push(<button key={"extra-display-btn"}>...</button>);
      }
      displayedPages.push(
        createButton(totalPage, currentPage, handlePaginationButton)
      );
    }

    return displayedPages;
  };
  const statusesWithIcon = formConfig?.listView?.statusesWithIcon;

  const statusesWithColor = formConfig?.listView?.statusesWithColor;

  const getAlstatusesNames = () => {
    let filterData = statusesWithIcon.filter((status) => {
      if (moduleType === "jrf") {
        if (user?.role === "LR") {
          return !["saved", "rejected"].includes(status.name);
        } else if (user?.role === "BU") {
          return !["awaited"].includes(status.name);
        }
      } else {
        return true;
      }
    });
    return filterData;
  };
  const getAlstatusesCounts = () => {
    let filterData = statusesWithColor.filter((status) => {
      if (moduleType === "jrf") {
        if (user?.role === "LR") {
          return !["saved", "rejected"].includes(status.status);
        } else if (user?.role === "BU") {
          return !["Approval Pending"].includes(status.name);
        }
      } else {
        return true;
      }
    });
    return filterData;
  };

  function getImageObject(imageName) {
    switch (imageName) {
      case "PartialIcon":
        return PartialIcon;
      case "PostedIcon":
        return PostedIcon;
      case "CompletedIcon":
        return CompletedIcon;
      case "RejectedIcon":
        return RejectedIcon;
      case "AcceptedIcon":
        return AcceptedIcon;
      case "InprocessIcon":
        return InprocessIcon;
      case "AllotedIcon":
        return AllotedIcon;
      case "PendingIcon":
        return PendingIcon;
      default:
        // Default return if the image name is not found
        return null;
    }
  }
  const getStatus = (formConfig, row) => {
    switch (moduleType) {
      case "sampleinward":
        return row["smpl_status"];
      case "testmemomain":
      case "allotment":
      case "sampleverification":
        return row["status"];
      case "sfm":
        return row["sfm_status"];
      case "internalcertificate":
        return row["status"];
      case "jobinstruction":
        return row["ji_internal_status"];
      case "vesselOperation":
        return row["status"];
      default:
        return row["jrf_status"];
    }
  };

  const getTdBorderColor = (row) => {
    const fieldNameData = section.headers?.filter(
      (header, index) => header.fieldName == "status"
    );
    let fieldName = fieldNameData.length > 0 ? fieldNameData[0].name : "";
    if (!fieldName) {
      return "border-top";
    }
    let cellData = row[fieldName];
    let statusData = statusesWithColor;
    let filterStatusData = statusData.filter((jrfColor, jrfColorIndex) => {
      if (Array.isArray(jrfColor.status)) {
        return jrfColor.status.includes(cellData);
      } else {
        return (
          jrfColor.status &&
          cellData &&
          jrfColor.status.toLowerCase() == cellData.toLowerCase()
        );
      }
    });
    // if (filterStatusData.length > 0) {
    //   filterStatusData = filterStatusData[0];
    //   return "border-top " + filterStatusData?.icon + "_tr";
    // } else {
    //   return "border-top completed_status_tr";
    // }
    return "border-top";
  };

  const getStatusNameValue = (cellData) => {
    let statusData = statusesWithColor;

    let filterStatusData = statusData.filter((jrfColor, jrfColorIndex) => {
      if (Array.isArray(jrfColor.status)) {
        return jrfColor.status.includes(cellData);
      } else {
        return (
          jrfColor &&
          cellData &&
          jrfColor.status.toLowerCase() == cellData.toLowerCase()
        );
      }
    });
    if (filterStatusData.length > 0) {
      filterStatusData = filterStatusData[0];
      return (
        <td key="status_list">
          <div
            className={"table_item_sym " + filterStatusData?.icon + "_bg"}
            key={"table-item"}
          >
            {/* <div className={filterStatusData?.icon}> </div> */}
            {filterStatusData?.name}
          </div>
        </td>
      );
    } else {
      return (
        <td key="status_list">
          <div className="table_item_sym" key={"table-item"} title="">
            <div className="posted_sym"> </div>
            {cellData}
          </div>
        </td>
      );
    }
  };

  const getFilteredStatusCount = (status) => {
    let module = moduleType;
    status = status.toLowerCase();
    if (module == "internalcertificate") {
      if (status == "approved") {
        if (user.role == "DTM") {
          status = "dtm-approved";
        } else {
          status = "tm-approved";
        }
      } else if (status == "rejected") {
        if (user.role == "DTM") {
          status = "dtm-rejected";
        } else {
          status = "tm-rejected";
        }
      }
    }
    let statusData =
      statusCounts[module] && statusCounts[module].length > 0
        ? statusCounts[module].filter((singleData) => {
            return singleData.status === status;
          })
        : [];
    return statusData.length > 0 ? statusData[0].count : 0;
  };
  const divRef = useRef(null);
  /*
  Author : Yash Darshankar
  Date : 20/06/2024
  Description : This code is used to close the popup when clicked outside the popup.
  */
  const popupOptionsRef = useRef(null);
  const [isBottom, setIsBottom] = useState(false);
  const pageLimit =
    currentPage === totalPage
      ? Math.floor(countPage % sizeofPage) > 3
        ? Math.floor(countPage % sizeofPage)
        : sizeofPage
      : sizeofPage;
  useEffect(() => {
    if (pageLimit - 2 === popupIndex || pageLimit - 1 === popupIndex) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }, [popupIndex]);

  const popupIntentionallyOpenedRef = useRef(false);
  /*
   */
  const [dontClick, setDontClick] = useState(false);
  useEffect(() => {
    const handler = (event) => {
      const isLastTd = event.target.classList.contains("last-td");
      const isInsidePopup =
        popupOptionsRef.current &&
        popupOptionsRef.current.contains(event.target);
      if (
        !isInsidePopup &&
        !popupIntentionallyOpenedRef.current &&
        !isLastTd &&
        !dontClick
      ) {
        setPopupIndex(-1);
      }
      popupIntentionallyOpenedRef.current = false;
    };

    document.addEventListener("click", handler);
    setDontClick(false);
    return () => document.removeEventListener("click", handler);
  }, [dontClick]);

  const checkIsShow = (fieldName) => {
    if (moduleType === "allotment") {
      if (
        fieldName === "inward_detail" ||
        fieldName === "sample_allotedto_data"
      ) {
        if (user?.role === "LC") {
          return false;
        }
      }
    } else if (moduleType === "jrf") {
      if (fieldName === "jrf_lab_detail") {
        if (user?.role === "LR") {
          return false;
        }
      } else if (fieldName === "jrf_branch_detail") {
        if (user?.role === "BU") {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnClick = (row) => {
    navigate(
      "/operation/vessel-ji-list/vessel-list/" +
        encryptDataForURL(formData[0]?.["ji_id"]) +
        "/" +
        encryptDataForURL(row["activity_master"]["activity_name"]) +
        "?OperationType=" +
        encryptDataForURL(row["activity_master"]["activity_name"]) +
        "&operationId=" +
        encryptDataForURL(row["jis_id"]) +
        "&operationStepNo=" +
        encryptDataForURL(1)
    );

    // return
    // if (row['status'] === "in-process") {
    //   navigate(`/operation/vessel-ji-list/vessel-list/${encryptDataForURL(formData[0].ji_id)}/${encryptDataForURL('analysis')}/${encryptDataForURL(row['jis_id'])}?OperationType=${encryptDataForURL(row['activity_master']['activity_name'])}&operationId=${encryptDataForURL(row['jis_id'])}`)
    // }
    // else {
    //   navigate('/operation/vessel-ji-list/vessel-list/' + encryptDataForURL(formData[0]?.['ji_id']) + "/" + encryptDataForURL(row['activity_master']['activity_name']) + "?OperationType=" + encryptDataForURL(row['activity_master']['activity_name']) + "&operationId=" + encryptDataForURL(row['jis_id']))
    // }
  };
  return (
    <div key={sectionIndex} className="row my-2 mx-0 renderList_header_table">
      {/* <CardTitle tag="h5" className="list_title">
        <div className="list_breadcrumb">
          {formConfig.label}
        </div>
      </CardTitle> */}
      {/* <div className="header_table renderList_table">

        <Row>
          <Col xs={6} className="header_table_box1_width">
            <div className="header_table_box1">
              {getAlstatusesNames().map(
                (jrfStatus, jrfStatusIndex) =>
                (
                  <div
                    className="header_table_status_item"
                    key={"jrf-status" + jrfStatusIndex}
                  >
                    {jrfStatus.image ? (
                      <img
                        src={getImageObject(jrfStatus.image)}
                        alt="jrf Icon"
                      />
                    ) : (
                      <i className={jrfStatus.icon + " list_icon"}></i>
                    )}
                    <p>{jrfStatus.label}</p>
                    <div>{getFilteredStatusCount(jrfStatus.name)}</div>
                  </div>
                )
              )}
            </div>

            {/* <div className="status-dropdown">
              <select className="form-control rounded-2">
                {statusesWithIcon?.map((jrfStatus, jrfStatusIndex) => (
                  <option
                    key={"jrf-status" + jrfStatusIndex}
                    value={jrfStatus.name}
                  >
                    <div
                      className="header_table_status_item"
                      key={"jrf-status" + jrfStatusIndex}
                    >
                      {jrfStatus.image ? (
                        <img
                          src={getImageObject(jrfStatus.image)}
                          alt="jrf Icon"
                        />
                      ) : (
                        <i className={jrfStatus.icon + " list_icon"}></i>
                      )}
                      <p>{jrfStatus.label}</p>
                      <div> ({getFilteredStatusCount(jrfStatus.name)})</div>
                    </div>
                  </option>
                ))}
              </select>
            </div> 
          </Col>
          <Col xs={6} className="header_table_box2_width">
            <div className="header_table_box2 renderSubList_box2">
              {getAlstatusesCounts().map(
                (jrfColor, jrfColorIndex) =>
                (
                  <div
                    className="header_table_status_item2"
                    key={"jrf-color" + jrfColorIndex}
                  >
                    <div className={jrfColor.icon}></div>
                    <p>{jrfColor.name}</p>
                  </div>
                )
              )}
            </div>
          </Col>
        </Row>
      </div> */}
      {/* <br/>
      <br/> */}
      <div className="renderList_table_container">
        {" "}
        <div className="renderList_table" ref={divRef}>
          <h2>Vessel Operations</h2>
          <table className="table table-white responsive borderless no-wrap align-middle list">
            <thead>
              <tr className="border-top">
                <th>Sr. No.</th>
                {section.headers?.map(
                  (header, headerIndex) =>
                    checkIsShow(header.name) && (
                      <th
                        key={"HeaderIndex -" + headerIndex}
                        colSpan={header.colSpan ?? 1}
                        onClick={() =>
                          handleClick(headerIndex, header?.sortName)
                        }
                      >
                        {header?.label}
                        <span className="table_header_icon">
                          {sortStates[headerIndex] ? (
                            <i className="bi bi-caret-up-fill"></i>
                          ) : (
                            <i className="bi bi-caret-down-fill"></i>
                          )}
                        </span>
                      </th>
                    )
                )}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subTableData?.map((row, rowIndex) => (
                <tr
                  key={"rowIndex-" + rowIndex}
                  // className={getTdBorderColor(row)}
                  className="border-top"
                >
                  <td key={"rowIndex-" + rowIndex + "1"}>{rowIndex + 1}</td>
                  {section.headers?.map((header, index) => {
                    if (!checkIsShow(header.name)) {
                      return null;
                    }
                    let cellData = formData[0]?.[header?.name];
                    if (!cellData) {
                      cellData = row[header?.name];
                    }
                    if (header?.fieldName === "status") {
                      return getStatusNameValue(row[header?.name]);
                    } else if (header?.fromType === "array") {
                      return (
                        <td key={"cellIndex" + index}>
                          {cellData
                            ? header.type === "date"
                              ? getFormatedDate(cellData[header?.fieldName], 1)
                              :header.fieldName==="company_code" ? getComonCodeForCompany(cellData[header?.fieldName]) : cellData[header?.fieldName]
                            : "--"}
                        </td>
                      );
                    } else {
                      return (
                        <td key={"cellIndex" + index}>
                          {header.type === "date"
                            ? getFormatedDate(cellData, 1)
                            : cellData}
                        </td>
                      );
                    }
                  })}

                  <td className="exclude-click last-td" ref={popupRef}>
                    {
                      // row['status'] !== "posted" &&
                      <div className="actionColumn maxWidth d-flex">
                        <button
                          type="button"
                          onClick={() => {
                            popupIntentionallyOpenedRef.current = true; // Indicate the popup was intentionally opened
                            setPopupIndex((prevIndex) => {
                              return prevIndex === rowIndex ? -1 : rowIndex;
                            });
                          }}
                          aria-label="Toggle popup"
                          className="invisible-button"
                        >
                          <i className="bi bi-three-dots-vertical"></i>
                        </button>
                        <span ref={popupOptionsRef}>
                          {popupIndex === rowIndex ? (
                            <PopupOptions
                              section={section}
                              popupActions={filteredAction}
                              setPopupIndex={setPopupIndex}
                              getAllListingData={getAllListingData}
                              id={
                                moduleType === "sampleinward"
                                  ? row["smpl_jrf_id"]
                                  : row["jrf_id"]
                              }
                              sampleInwardFormId={row["smpl_inwrd_id"]}
                              row={row}
                              formConfig={formConfig}
                              model={responseData.model}
                              isBottom={isBottom}
                              status={getStatus(formConfig, row)}
                              setDontClick={setDontClick}
                              from="subListTable"
                            />
                          ) : null}
                        </span>
                        {!["posted", "input-completed"].includes(
                          row["status"]
                        ) && (
                          <div className="">
                            <button
                              type="button"
                              className="iconBtn"
                              onClick={() => handleOnClick(row)}
                            >
                              Edit
                            </button>
                          </div>
                        )}
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
RenderSubListSection.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.object),
  responseData: PropTypes.object,
  getAllListingData: PropTypes.func,
  formConfig: PropTypes.object,
  statusCounts: PropTypes.object,
  setIsRejectPopupOpen: PropTypes.func,
  setJRFCreationType: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  loadingTable: PropTypes.bool,
  setIsOverlayLoader: PropTypes.func,
  moduleType: PropTypes.string,
  formData: PropTypes.object,
};
export default RenderSubListSection;
