export const LoginApi = "/login/";
export const LogOutApi = "/logout/";
export const forgotPasswordApi = "/users/forgot-password/";
export const verifyOtpApi = "/verify-otp/";
export const resendOtpApi = "/users/resend-otp/";
export const forgotPassOTPcheckApi = "/forgotpassotpcheck/";
export const resetPasswordApi = "/users/reset-password-confirm/";

export const jrfSearchApi = "/jrf/search/";
export const referenceDataApi = "/referenceData/";
export const JRFDeleteApi = "/jrf/delete";
export const JRFPDFDownloadApi = "/jrf_pdf/get";
export const JRFGetApi = "/jrf/get";
export const pdfDetailsApi = "/pdfdetails/get";

export const MasterListApi = "/masters/list";

export const HistoryApi = "/users/object-history/";
export const sampleDetailsAPI = "/sampleinward/create";
export const sampleInwardDetailsDeleteAPI = "sampleinward/delete";
export const sampleInwardDetailsGetAPI = "/sampleinward/get";
export const sampleInwardDetailsUpdateAPI = "/sampleinward/update";
export const sampleInwardUpdate = "/sampleinward/update";
export const sampleIdsApi = "/sampleids/";
export const checkSampleIdAvailable = "/checksampleidexists/get/";

export const allotmentCreateApi = "/allotment/create";
export const allotmentUpdateApi = "/allotment/update";
export const allotmentDeleteApi = "/allotment/delete";
export const getSimgleAllotmentDetailsApi = "/allotment/get";

export const sampleverificationdetailCreateApi =
  "/sampleverificationdetail/create";
export const sampleverificationdetailDeleteApi =
  "/sampleverificationdetail/delete/";
export const sampleverificationdetailUpdateApi =
  "/sampleverificationdetail/update";
export const getsamplelabcodeApi = "/samplelabcode/get";

export const sampleverificationCreateApi = "/sampleverification/create";
export const sampleverificationUpdateApi = "/sampleverification/update";
export const sampleverificationDeleteApi = "/sampleverification/delete";
export const sampleverificationSingleApi = "/sampleverification/get";
export const labparametersApi = "/labparameters/get";
export const labGrouoparametersApi = "/lmsgrouporparam/get";
export const OPESGrouoparametersApi = "/opsgrouporparam/get";
export const labGroupsStdBasisApi = "/groupstdbasis/get";
export const labOPEGroupsStdBasisApi = "/opsstdbasis/get";
export const labparambasisApi = "/parambasis/get/";
export const labOPEparambasisApi = "/opsparambasis/get/";
export const labdropdownApi = "/labdropdown/get/";
export const statuscountApi = "/statuscount/";

export const testMemoGetApi = "/testmemo/get";
export const testReportGetApi = "/testreport/get";
export const testMemoGetSamplesetsApi = "/samplesets/get";
export const testMemoGetParambasisstdApi = "/parambasisstd/get";
export const testMemoCreateasyncApi = "/testmemo/create";
export const testMemoDeleteApi = "/testmemo/delete";
export const testMemoUpdateApi = "/testmemo/update";
export const geChemistUserApi = "/chemistuser/get";
export const scopenonscopecountApi = "/scopenonscopecount/get/";

export const SFMCreateApi = "/sfm/create";
export const SFMUpdateApi = "/sfm/update";
export const SFMDeleteApi = "/sfm/delete";
export const SFMGetApi = "/sfm/get";
export const SFMSetCountApi = "/sfmstatuscount/get";
export const SFMbasisupdateApi = "/sfmbasisupdate/update";
export const SFMRejectFlowApi = "/sfmrejectflow/";

export const InternalCertificateCreateApi = "/internalcertificate/create";
export const InternalCertificateUpdateApi = "/internalcertificate/update";
export const InternalCertificateDeleteApi = "/internalcertificate/delete";
export const InternalCertificateGetApi = "/internalcertificate/get";
export const testReportDetailsApi = "/testreportdetails/get";
export const checkexistingulrnoApi = "/checkexistingulrno/get/";

export const refreshApi = "/api/token/refresh/";
export const formulaListapi = "/formula/list/";
export const formulagetapi = "/formula/get/";
export const formulaCreateapi = "/formula/create/";

//Operations Apis
export const getClientListDataApi = "/masters/customer/list/";
export const getPlaceDataApi = "masters/placeofwork/list/";
export const getSubplierDataApi = "masters/supplier/list/";
export const getSubCommodityDataApi = "/ji-commodity/get/";
export const getClientDataApi = "/ji-client/get/";
export const getOperationTypeDataApi = "/masters/opmaster/list/";
export const getActivityDataApi = "/masters/activitymaster/list/";
export const getActivityDatabyOperationApi = "/activitydropdown/list/";
// export const getscopeofworkDataApi="/masters/scopeofwork/list/"
export const getscopeofworkDataApi = "/scopeofworkdropdown/get/";
export const getAllPortDataApi = "/masters/port/list/";
export const getScopeworkCreateApi = "/ji-scopeofwork/create/";
export const getScopeworkUpdateApi = "/ji-scopeofwork/update/";
export const getScopeworkDeleteApi = "/ji-scopeofwork/delete/";
export const getJIQualityAnalysisCreateApi = "/ji-quality-analysis/create/";
export const getJIQualityAnalysisUpdateApi = "/ji-quality-analysis/update/";
export const getJIQualityAnalysisDeleteApi = "/ji-quality-analysis/delete/";

export const getBranchDetailsApi = "/ji-branch/get/";
export const getJIsowandactivityApi = "/ji-sowandactivity/list/";

export const TMLDataCreateApi = "/ji-samplemarks/create/";
export const TMLDataUpdateApi = "/ji-samplemarks/update/";
export const TMLDatagetAllApi = "/ji-samplemarks/list/";
export const TMLDataDeleteApi = "/ji-samplemarks/delete/";
export const TMLAnalysisDataCreateApi = "/ji-lms-assignment/create/";
export const TMLAnalysisDataListApi = "/ji-lms-assignment/list/";
export const sampleMarkOptionsApi = "ops-samplemarks/get/";
export const sampleMarkOptionsLotWiseApi = "/lotwise-samplemark/get/";
export const getJRFOperationDataApi = "/ops_lms_data/get/";
export const deleteTMLAnalysisDeleteApi = "/ji-lms-assignment/delete/";

export const jobinstructionUpdateApi = "/jobinstruction/update/";
export const jobinstructionDeleteApi = "/jobinstruction/delete/";

export const createOtherTpiApi = "/tpi-async-task/create/";
export const getTpiSetApi = "/tpi-set/get/";
export const getTpiParamBasistApi = "/tpi-paramstdbasis/get/";
export const getTpibasisvalueUpdateApi = "/tpi-basisvalue/update/";
export const TPISetCountApi = "/tpi-markcount/get/";
export const notificationListApi = "/notifications/list/";

//
export const createHHApi = "/ops-vessel-hh/create/";
export const updateHHApi = "/ops-vessel-hh/update/";
export const getHHApi="/ops-vessel-hh/get/"
export const createSVApi = "/ops-vessel-sv/create/";
export const updateSVApi = "/ops-vessel-sv/update/";
export const getSVApi = "/ops-vessel-sv/get/";
export const createDSApi = "/ops-vessel-ds/create/";
export const updateDSApi = "/ops-vessel-ds/update/";
export const getDSApi = "/ops-vessel-ds/get/";

export const getSalesPersonApi = "/sales-user/list/";

export const createOPSExecApi = "/opsexec-grpparam/create/";
export const getOPSExecApi = "/opsexec-grpparam/list/";
export const deleteOPSExecApi = "/opsexec-grpparam/delete/";

export const getlotnodropdownApi = "lotnodropdown/get/";
export const getAssignemtnLabDropdownApi="/lab-dropdown/list/"
export const opsSizeAnalysisCreateApi="/ops-sizeanalysis/create/"
export const opsSizeAnalysisUpdateApi="/ops-sizeanalysis/update/"
export const opsSizeAnalysisGetApi="/ops-sizeanalysis/get/"

export const getLastStepAssignmentData="/lab-dropdown-lmsassignment/get/"
export const getLabWiseSetAssignmentData="/labwise-set/get/"
