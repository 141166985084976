import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  NavItem,
  NavLink,
  Col,
} from "react-bootstrap";
import classnames from "classnames";
import RenderTableSection from "./RenderTableSection";
import RenderAssignmentTableSection from "./RenderAssignmentTableSection";
import RenderTableSectionCertificate from "./RenderTableSectionCertificate";
import RenderAdvTableSection from "./RenderAdvTableSection";
import PropTypes from "prop-types";

import RenderListSection from "./RenderListSection";
import RenderAccordionSection from "./RenderAccordionSection";
import RenderFields from "./RenderFields";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "./Popup";
import PopupGA from "./PopupGAForm";
import PopupSearch from "./PopupSearch";
import Loading from "./Loading";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  getDataFromApi,
  GetTenantDetails,
  postDataFromApi,
  putDataFromApi,
} from "../../services/commonServices";
import {
  SFMUpdateApi,
  allotmentCreateApi,
  allotmentUpdateApi,
  getJRFOperationDataApi,
  getSimgleAllotmentDetailsApi,
  sampleverificationCreateApi,
  sampleverificationSingleApi,
} from "../../services/api";
import {
  CommonTMRoles,
  isModuelePermission,
  rolesDetails,
} from "../../services/commonFunction";
import { useSelector } from "react-redux";
import DeleteConfirmation from "./DeleteConfirmation";
import RejectPopupModal from "./RejectPopupModal";
import { useTranslation } from "react-i18next";
import RenderTablePreview from "./RenderTableSectionPreview";
import {
  getJRFOperationData,
  getReferenceData,
  handleJRFCreateOrUpdate,
  handleJRFCreateOrUpdateWithOperations,
} from "./commonHandlerFunction/JRFHandlerFunctions";
import {
  handleChecklistBtns,
  handleInwardMainSubmit,
  handleInwardStatusChange,
} from "./commonHandlerFunction/sampleInwardHandlerFunctions";
import GroupAssignmentButtons from "./ShowButtons/GroupAssignmentButtons";
import GroupAssignmentPreviewButtons from "./ShowButtons/GroupAssignmentPreviewButtons";
import SampleInwardButtons from "./ShowButtons/SampleInwardButtons";
import InternalCertificateButtons from "./ShowButtons/InternalCertificateButtons";

import ViewCheckListButtons from "./ShowButtons/ViewCheckListButtons";
import JRFButtons from "./ShowButtons/JRFButtons";
import JIButtons from "./ShowButtons/operations/JIButtons";
import TestMemoButtons from "./ShowButtons/TestMemoButtons";
import SampleVerificationButtons from "./ShowButtons/SampleVerificationButtons";
import AllotmentButtons from "./ShowButtons/AllotmentButtons";
import {
  changeTestMEmoStatuChange,
  getTestMemoDetails,
  getTestMemoDetailsWithCertificate,
  handleTestMemoDelete,
  handleTestMemoStatusChange,
} from "./commonHandlerFunction/testMemoFunctionHandler";
import RenderAdvtestMemoTableSection from "./RenderAdvtestMemoTableSection";
import { handleVerificationMain } from "./commonHandlerFunction/sampleVerificationHandlerFunctions";
import RenderAdvSFMTableSection from "./RenderAdvSFMTableSection";
import {
  getallFormulaList,
  getSFMDetails,
  handleAllotUpdate,
  handleSFMCreateWithoutVerification,
  handleSFMVerificationMain,
} from "./commonHandlerFunction/sfmHandlerFunctions";
import SFMButtons from "./ShowButtons/SFMButtons";
import {
  changeTestReportStatusChange,
  checkICULRNoAvailibility,
  getCertificateDetailsById,
  handleIntarnalCertificateCreateUpdate,
} from "./commonHandlerFunction/intenralCertificateHandlerFunction";
import { saveAs } from "file-saver";
import OverlayLoading from "./OverlayLoading";
import {
  createQualityAnalysisHandler,
  getBranchDetailsById,
  getclientDetails,
  getSingleJiRecord,
  getSubCommodityData,
  handleJICreateOrUpdate,
  handleJIUpdateStatus,
  handleJIValidation,
} from "./commonHandlerFunction/jobinstructionHandlerFunctions";
import RenderSubListSection from "./RenderSubListSection";
import {
  getSingleDraftSurveyData,
  getSingleHHData,
  getSingleSupervissionData,
  jobInstructionUpdateDataFunction,
  Operation_DraftSurvey_CreateDataFunction,
  Operation_HH_CreateDataFunction,
  Operation_Supervision_CreateDataFunction,
  OperationCreateDataFunction,
  OperationSizeAnalysisCreateDataFunction,
} from "./commonHandlerFunction/operations/TMLOperations";
import {
  decryptDataForURL,
  encryptDataForURL,
} from "../../utills/useCryptoUtils";
import RenderTableOperationSection from "./RenderTableOperationSection";
import {
  getTPISetDetails,
  handleSFMCreateWithOperations,
} from "./commonHandlerFunction/operations/TPIHandlerFunctions";
import RenderTableSetAllManualSection from "./RenderTableSetAllManualSection";
import SearchIcon from "../../assets/images/icons/searchIcon.svg";
import { isValidPhoneNumber } from "react-phone-number-input";
import RenderTableForDraftSurveySection from "./RenderTableForDraftSurveySection";
import JIPopupModal from "./JIPopupModal";
export const selectUser = (state) => state.user;
export const selectRolePermissions = (state) => state.rolePermissions;

const Forms = ({
  formConfig,
  masterResponse,
  getSampleIdsMasterData,
  searchConfigJson,
  getAssignmentMasterData,
  setTestMemoId,
  testMemoId,
  testReportPrweview,
  isExternalJRF,
  totalSamples,
  setMasterResponse,
  useForComponent,
  isViewOnlyTable,
  operationStepNo,
  tileHeader,
}) => {
  let { EditRecordId, editReordType, TMLType, TMLID, operationName } =
    useParams();
  EditRecordId = EditRecordId ? decryptDataForURL(EditRecordId) : "";
  TMLType = TMLType ? decryptDataForURL(TMLType) : "";
  TMLID = TMLID ? decryptDataForURL(TMLID) : "";
  editReordType = editReordType ? decryptDataForURL(editReordType) : "";
  operationName = operationName ? decryptDataForURL(operationName) : "";
  const { t } = useTranslation();
  const translate = t;
  let user = useSelector(selectUser);
  const session = useSelector((state) => state.session);

  user = session.user;

  let rolePermissions = useSelector(selectRolePermissions);
  rolePermissions = session?.user?.permissions;
  const [activeTab, setActiveTab] = useState("1-0");
  const [formData, setFormData] = useState({});
  const [searchFormData, setSearchFormData] = useState({});
  const [searchFormDataType, setSearchFormDataType] = useState({});
  const [searchFormDataErros, setSearchFormDataErros] = useState({});
  const [isFilterBtnClicked, setIsFilterBtnClicked] = useState(false);

  const [filterIndex, setFilterIndex] = useState(1);

  const [formErrors, setFormErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formConfigState, setFormConfigState] = useState(formConfig);
  const [rowAdded, setRowAdded] = useState(false);
  const [searchby, setSearchby] = useState(false);

  const [response, setResponse] = useState({});
  const [statusCounts, setStatusCounts] = useState([]);
  const [jrfId, setJrfId] = useState();
  const [referenceData, setReferenceData] = useState({});
  const [isValideReferenceNo, setIsValideReferenceNo] = useState(true);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [jrfCreationType, setJrfCreationType] = useState("");
  const [viewOnly, setViewOnly] = useState(false);
  const [pageType, setPageType] = useState("");
  const [remarkText, setRemarkText] = useState("");
  const [isRejectPopupOpen, setIsRejectPopupOpen] = useState(false);
  const [action, setAction] = useState("");
  const [actionClicked, setActionClicked] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [inwardBtnchange, setInwardBtnchange] = useState("");
  const [subTableData, setSubTableData] = useState([]);
  const [istavSaveClicked, setIstavSaveClicked] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");
  ////For Group Assignment Only....
  const [gaData, setGaData] = useState([]);
  const [showModalGA, setShowModalGA] = useState(false);
  const [tabOpen, setTabOpen] = useState(true);
  const [tabOpenSecond, setTabOpenSecond] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [simpleInwardResponse, setSimpleInwardResponse] = useState([]);
  const [testMemoSetData, setTestMemoSetData] = useState([]);
  const [groupDefaultValue, setGroupDefaultValue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [isDisplayNewAddOption, setIsDisplayNewAddOption] = useState(false);
  const [testReportData, setTestReportData] = useState("");
  const [subCommodityOptions, setSubCommodityOptions] = useState([]);
  const [isRefrenceNoCalled, setIsRefrenceNoCalled] = useState(true);
  const [isOverlayLoader, setIsOverlayLoader] = useState(false);
  const [allFormulaList, setAllformulaList] = useState([]);
  const [editModuleId, setEditModuleId] = useState("");
  const [OperationType, setOperationType] = useState("");
  const [OperationTypeID, setOperationTypeID] = useState("");
  const moduleType = formConfig?.sections[0]?.moduleType;
  const moduleSubType = formConfig?.sections[0]?.moduleSubType;
  const listModuleType = formConfig.listView?.moduleType;
  const [isTestMethods, setIstestMethods] = useState(false);
  const [isValideValue, setIsValideValue] = useState(false);
  const [JRFTPIFormData, setJRFTPIFormData] = useState({});
  const [viewDetail, setViewDetail] = useState(false);
  const [parameterDataTableMain, setParameterDataTableMain] = useState([]);
  let navigate = useNavigate();

  let vieableArr = [
    "view",
    "inward",
    "assignment",
    "checklist",
    "assignment-view",
  ];
  useEffect(() => {
    if (formConfig.listView) {
      setLoading(false);

      getAllListingData();
      getAllStatusCount();
    } else {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    if (moduleType === "allotment") {
      const id = decryptDataForURL(params.get("testMemoId"));
      const view = decryptDataForURL(params.get("view"));

      if (view === "view") {
        setViewOnly(true);
        setAction(view);
      }
      setTestMemoId(id);
      if (id) {
        getTestMemoDetails(
          id,
          setTabOpen,
          setFormData,
          setTestMemoSetData,
          "",
          setIsOverlayLoader
        );
      } else {
        getAllotmentDetails();
      }
    } else if (moduleType === "sampleverification") {
      const sampleAllotmentId = decryptDataForURL(
        params.get("sampleAllotmentId")
      );
      const view = decryptDataForURL(params.get("view"));
      if (view === "view") {
        setViewOnly(true);
        setAction("View");
      }
      if (sampleAllotmentId) {
        getAllotmentDetails();
      } else {
        getVerificationDetails();
      }
    } else if (moduleType === "testmemomain") {
      const id = decryptDataForURL(params.get("testMemoId"));
      const view = decryptDataForURL(params.get("view"));
      const status = decryptDataForURL(params.get("status"));
      setPageType(status);
      if (view == "view") {
        setViewOnly(true);
        setAction(view);
      }
      setTestMemoId(id);
      getTestMemoDetails(
        id,
        setTabOpen,
        setFormData,
        setTestMemoSetData,
        "",
        setIsOverlayLoader
      );
    } else if (moduleType == "internalcertificate") {
      const id = decryptDataForURL(params.get("testMemoId"));
      const editId = decryptDataForURL(params.get("editId"));
      const view = decryptDataForURL(params.get("view"));
      const status = decryptDataForURL(params.get("status"));
      setPageType(status);
      if (view === "view" || user?.role !== "LR") {
        setViewOnly(true);
        setAction(view);
      }
      if (editId) {
        getCertificateDetailsById(
          editId,
          setTabOpen,
          setFormData,
          setTestMemoId,
          "",
          setViewOnly,
          getAssignmentMasterData,
          setIsValideValue,
          setSubTableData
        );
      } else if (id) {
        setTestMemoId(id);
        getTestMemoDetailsWithCertificate(
          id,
          setTabOpen,
          setFormData,
          setTestMemoSetData,
          setTestReportData,
          getAssignmentMasterData,
          setIsValideValue,
          user
        );
      }
    } else if (moduleType == "sfm") {
      const id = decryptDataForURL(params.get("sfmid"));
      const view = decryptDataForURL(params.get("view"));
      if (view === "view") {
        setViewOnly(true);
      }
      getSFMDetails(
        id,
        setFormData,
        setTabOpen,
        setIstavSaveClicked,
        setTestMemoSetData
      );
      getallFormulaList(setAllformulaList);
    } else if (moduleType == "jobinstruction") {
      setTabOpen(false);
    } else {
      getSingleData();
      if (isExternalJRF) {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              jrf_ext_contactpersonname:
                user.logged_in_user_info.contact_person_name,
              jrf_ext_contactpersonnumber:
                user.logged_in_user_info.contact_person_number,
              cmp_address:
                user?.logged_in_user_info?.lab_or_branch?.lab_address,
              jrf_lab: user?.logged_in_user_info?.lab_or_branch?.lab_id,
            },
          };
        });
      } else {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              jrf_contact_person: user.logged_in_user_info.contact_person_name,
              jrf_contact_person_number:
                user.logged_in_user_info.contact_person_number,
              cmp_address:
                user?.logged_in_user_info?.lab_or_branch?.branch_address,
            },
          };
        });
      }
    }
  }, []);
  useEffect(() => {
    if (referenceNo !== "") {
      getReferenceData(
        referenceNo,
        setIsValideReferenceNo,
        formData,
        setReferenceData,
        setFormData,
        setSubCommodityOptions,
        setIsRefrenceNoCalled,
        setIsOverlayLoader
      );
    }
  }, [referenceNo]);
  useEffect(() => {
    // Reset rowAdded to false after the component has re-rendered
    setRowAdded(false);
  }, [rowAdded]); // Run this effect whenever rowAdded changes
  useEffect(() => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        0: {
          ...prevFormData[0],
          noOfSamples: totalSamples ? totalSamples.length : 0,
        },
      };
    });
  }, [formData[0]?.smpl_inwrd_No, totalSamples]);
  useEffect(() => {
    if (formConfig?.sections[0]?.moduleType === "internalcertificate") {
      if (
        formData[0]?.ic_is_size_analysis &&
        formData[0]?.ic_is_size_analysis[0]
      ) {
        setTabOpen(true);
      } else {
        setTabOpen(false);
      }
    }
  }, [formData[0]?.ic_is_size_analysis]);

  useEffect(() => {
    if (
      ["jobinstruction"].includes(moduleType) &&
      formData[0]?.fk_commodityid &&
      !editReordType
    ) {
      getSubCommodityData(
        formData[0]?.fk_commodityid,
        setMasterResponse,
        setIsOverlayLoader
      );
    }
  }, [formData[0]?.fk_commodityid]);
  useEffect(() => {
    if (["jobinstruction"].includes(moduleType) && formData[0]?.fk_branchid) {
      getBranchDetailsById(
        formData[0]?.fk_branchid,
        setMasterResponse,
        setIsOverlayLoader
      );
    }
  }, [formData[0]?.fk_branchid]);
  useEffect(() => {
    if (["jobinstruction"].includes(moduleType)) {
      if (!EditRecordId) {
        if (formData[0]?.fk_clientid) {
          getclientDetails(
            formData[0]?.fk_clientid,
            setFormData,
            setIsOverlayLoader
          );
        } else {
          setFormData((prevFormData) => {
            return {
              ...prevFormData,
              [0]: {
                ...prevFormData[0],
                ji_client_address: "-",
                ji_client_billing_address: "-",
                ji_client_gst: "-",
              },
            };
          });
        }
      }
    }
  }, [formData[0]?.fk_clientid]);
  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    const action = decryptDataForURL(params.get("action"));
    const opType = decryptDataForURL(params.get("OperationType"));
    const opTypeId = decryptDataForURL(params.get("operationId"));
    if (action === "View") {
      setViewOnly(true);
      setAction(action);
    }
    if (EditRecordId) {
      if (moduleType === "jobinstruction") {
        getSingleJiRecord(
          formConfig.apiEndpoints.read,
          EditRecordId,
          setFormData,
          setTabOpen,
          setIsOverlayLoader,
          editReordType
        );
      }
      setOperationType(opType);
      setOperationTypeID(opTypeId);
      if (TMLType === "analysis") {
        getSampleIdsMasterData(EditRecordId, TMLID);
      } else if (TMLType === "otherTPI") {
        console.log("EditRecordId", EditRecordId, "OperationTypeID", opTypeId);
        getallFormulaList(setAllformulaList);
        getTPISetDetails(
          setTestMemoSetData,
          EditRecordId,
          opTypeId,
          setFormData
        );
      }
    }
  }, [EditRecordId]);
  useEffect(() => {
    if (formData[0]?.["ji_id"]) {
      if (TMLType === "H&H") {
        getSingleHHData(
          OperationTypeID,
          formData,
          setSubTableData,
          setIsOverlayLoader,
          setFormData,
          formConfig.sections?.[1]?.tabs?.[0]
        );
      } else if (TMLType === "Draft Survey") {
        getSingleDraftSurveyData(
          OperationTypeID,
          formData,
          setSubTableData,
          setIsOverlayLoader,
          setFormData,
          formConfig.sections?.[1]?.tabs?.[0]
        );
      } else if (TMLType === "Supervision") {
        getSingleSupervissionData(
          OperationTypeID,
          formData,
          setSubTableData,
          setIsOverlayLoader,
          setFormData,
          formConfig.sections?.[1]?.tabs,
          subTableData
        );
      }
    }
  }, [OperationType, formData[1]?.["ji_id"]]);
  const getPopupConfirmationCallback = (redirectURL) => {
    if (inwardBtnchange) {
      if (inwardBtnchange === "allotment") {
        handleAllotSubmit();
      } else if (moduleType === "sampleverification") {
        return;
      } else if (inwardBtnchange === "sendToLab") {
        handleTestMemoStatusChange(
          testMemoId,
          navigate,
          "pending",
          formData,
          setIsOverlayLoader
        );
      } else if (inwardBtnchange === "reassign") {
        handleTestMemoDelete(testMemoId, navigate);
      } else {
        handleInwardStatusChange(
          inwardBtnchange,
          pageType,
          formData,
          navigate,
          jrfId,
          setIsOverlayLoader
        );
      }
    } else if (jrfCreationType && moduleType === "sampleverification") {
      if (jrfCreationType == "post") {
        handleVerificationMain(
          formData,
          navigate,
          testMemoId,
          setSaveClicked,
          setIsOverlayLoader
        );
      } else {
        navigate("/verificationList");
      }
    } else if (moduleType === "sfm") {
      if (jrfCreationType === "post") {
        handleSFMVerificationMain(
          formData,
          navigate,
          setLoading,
          setIsOverlayLoader
        );
      } else {
        navigate("/SFMList");
      }
    } else if (
      moduleType === "internalcertificate" ||
      listModuleType === "internalcertificate"
    ) {
      if (jrfCreationType == "save" || jrfCreationType == "post") {
        handleIntarnalCertificateCreateUpdate(
          formData,
          testMemoId,
          navigate,
          jrfCreationType,
          setIsOverlayLoader,
          subTableData
        );
      } else if (jrfCreationType == "approve") {
        changeTestReportStatusChange(
          decryptDataForURL(localStorage.getItem("icId")),
          navigate,
          "dtm-approved",
          "",
          1,
          getAllListingData,
          setIsPopupOpen,
          setIsOverlayLoader
        );
      } else if (jrfCreationType == "publish") {
        changeTestReportStatusChange(
          formData[0].ic_id,
          navigate,
          "publish",
          "",
          "",
          getAllListingData,
          setIsPopupOpen,
          setIsOverlayLoader
        );
      } else {
        navigate("/testReport");
      }
    } else if (moduleType === "jobinstruction") {
      if (
        jrfCreationType === "save" ||
        jrfCreationType === "post" ||
        jrfCreationType === "postOther" ||
        jrfCreationType === "postJRF"
      ) {
        if (useForComponent == "OperationDetails") {
          if (TMLType == "H&H") {
            Operation_HH_CreateDataFunction(
              formData,
              setIsOverlayLoader,
              setIsPopupOpen,
              OperationType,
              OperationTypeID,
              navigate,
              subTableData,
              jrfCreationType
            );
          } else if (OperationType == "Supervision") {
            Operation_Supervision_CreateDataFunction(
              formData,
              setIsOverlayLoader,
              setIsPopupOpen,
              OperationType,
              OperationTypeID,
              navigate,
              subTableData,
              jrfCreationType
            );
          } else if (OperationType == "Draft Survey") {
            Operation_DraftSurvey_CreateDataFunction(
              formData,
              setIsOverlayLoader,
              setIsPopupOpen,
              OperationType,
              OperationTypeID,
              navigate,
              formConfig.sections[1].tabs[0]
            );
          } else {
            if (operationStepNo == 4) {
              OperationSizeAnalysisCreateDataFunction(
                formData,
                setIsOverlayLoader,
                setIsPopupOpen,
                OperationType,
                OperationTypeID,
                navigate,
                subTableData,
                operationStepNo
              );
            } else {
              OperationCreateDataFunction(
                formData,
                setIsOverlayLoader,
                setIsPopupOpen,
                OperationType,
                OperationTypeID,
                navigate,
                "in-process",
                1,
                null,
                operationStepNo
              );
            }
          }
        } else if (useForComponent == "OperationDetailsAssignment") {
          let JRFData = [];
          let TPIData = [];
          for (let obj in JRFTPIFormData[0]) {
            let name = obj.split("_");
            const id = name[name.length - 1];

            if (JRFTPIFormData[0][obj] && JRFTPIFormData[0][obj][0] === "Yes") {
              JRFData.push(id);
            } else {
              TPIData.push(id);
            }
          }
          if (operationStepNo == 3) {
            OperationCreateDataFunction(
              formData,
              setIsOverlayLoader,
              setIsPopupOpen,
              OperationType,
              OperationTypeID,
              navigate,
              "in-process",
              1,
              null,
              operationStepNo
            );
          } else {
            if (jrfCreationType === "postJRF") {
              if (JRFData.length === 0) {
                toast.error("Please choose Parameter for sent To JRF", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setIsOverlayLoader(false);
                setIsPopupOpen(false);
                return;
              }
            } else if (jrfCreationType === "postOther") {
              if (TPIData.length === 0) {
                toast.error("Please Unselect Parameter for sent To Other TPI", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setIsOverlayLoader(false);
                setIsPopupOpen(false);
                return;
              }
            }
            OperationCreateDataFunction(
              formData,
              setIsOverlayLoader,
              setIsPopupOpen,
              OperationType,
              OperationTypeID,
              navigate,
              jrfCreationType === "post" ? "input-completed" : "in-process",
              jrfCreationType === "post" ? 1 : "",
              jrfCreationType === "post" ? [] : JRFData,
              operationStepNo,
              1
            );
            if (jrfCreationType === "postOther") {
              handleSFMCreateWithOperations(
                formData,
                OperationType,
                OperationTypeID,
                navigate,
                setIsOverlayLoader,
                setIsPopupOpen
              );
            } else if (jrfCreationType === "postJRF") {
              handleJRFCreateOrUpdateWithOperations(
                formData,
                setIsOverlayLoader,
                setIsPopupOpen,
                navigate,
                "/jrf/create",
                OperationTypeID,
                OperationType,
                JRFData
              );
            }
          }
        } else {
          if (editReordType === "analysis") {
            if (subTableData.length === 0 && parameterDataTableMain.length > 0) {
              createQualityAnalysisHandler(
                parameterDataTableMain,
                setIsOverlayLoader,
                formData
              );
            }
          }
          handleJICreateOrUpdate(
            formData,
            formConfig,
            setIsOverlayLoader,
            setIsPopupOpen,
            jrfCreationType,
            setTabOpen,
            setFormData,
            editReordType,
            navigate,
            setSubTableData,
            subTableData
          );
        }
      } else {
        navigate("/operation/jrfInstructionListing");
      }
    } else if (jrfCreationType) {
      handleJRFCreateOrUpdate(
        setSaveClicked,
        formData,
        referenceData,
        jrfId,
        formConfig,
        setJrfId,
        navigate,
        setIsPopupOpen,
        jrfCreationType,
        isExternalJRF,
        setIsOverlayLoader
      );
    } else {
      navigate(redirectURL);
    }
  };

  const openDeletePopup = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "";
    if (inwardBtnchange) {
      switch (inwardBtnchange) {
        case "post":
        case "save":
        case "assign":
        case "assignment":
        case "sendToLab":
        case "allotment":
        case "publish":
        case "approve":
        case "reassign":
          headingMsg = formConfig?.popupMessages[inwardBtnchange]?.headerMsg;
          titleMsg = formConfig?.popupMessages[inwardBtnchange]?.titleMsg;
          break;
        default:
          headingMsg = formConfig?.popupMessages.cancel?.headerMsg;
          titleMsg = formConfig?.popupMessages.cancel?.titleMsg;
          break;
      }
    } else {
      switch (jrfCreationType) {
        case "post":
        case "approve":
        case "publish":
        case "postJRF":
        case "postOther":
        case "save":
          headingMsg = formConfig?.popupMessages[jrfCreationType]?.headerMsg;
          titleMsg = formConfig?.popupMessages[jrfCreationType]?.titleMsg;
          break;
        default:
          headingMsg = formConfig?.popupMessages.cancel?.headerMsg;
          titleMsg = formConfig?.popupMessages.cancel?.titleMsg;
          break;
      }
    }

    let redirectURL = "";
    if (pageType === "inward" || pageType === "assignment") {
      redirectURL = "/inwardList";
    } else if (moduleType === "testmemomain") {
      redirectURL = "/testmemoList";
    } else if (moduleType == "internalcertificate") {
      redirectURL = "/testmemoList";
    } else if (moduleType == "allotment") {
      redirectURL = "/allotmentList";
    } else {
      redirectURL = "/jrfListing";
    }
    return (
      <DeleteConfirmation
        isOpen={isPopupOpen}
        handleClose={setIsPopupOpen}
        handleConfirm={() => getPopupConfirmationCallback(redirectURL)}
        popupMessage={titleMsg}
        popupHeading={headingMsg}
        saveClicked={saveClicked}
        isOverlayLoader={isOverlayLoader}
      />
    );
  };
  const rejectHandleer = (e) => {
    if (moduleType === "testmemomain") {
      let isSFM = "";
      if (formData[0].status === "results") {
        isSFM = 1;
      }
      changeTestMEmoStatuChange(
        testMemoId,
        navigate,
        "rejected",
        remarkText,
        setIsOverlayLoader,
        isSFM
      );
    } else if (
      moduleType === "internalcertificate" ||
      listModuleType == "internalcertificate"
    ) {
      let status = "tm-reject";
      let isRedirect = 0;
      let icId = formData[0].ic_id
        ? formData[0].ic_id
        : decryptDataForURL(localStorage.getItem("icId"));
      if (user?.role === "DTM") {
        status = "dtm-reject";
      }
      changeTestReportStatusChange(
        icId,
        navigate,
        status,
        remarkText,
        isRedirect,
        getAllListingData,
        setIsPopupOpen,
        setIsOverlayLoader
      );
    } else if (moduleType === "jobinstruction") {
      handleJIUpdateStatus(
        formData,
        formConfig,
        setIsOverlayLoader,
        editReordType,
        navigate,
        1,
        "rejected",
        remarkText,
        subTableData
      );
    } else {
      handleChecklistBtns(
        e,
        "reject",
        formData,
        remarkText,
        setSaveClicked,
        formConfig,
        navigate,
        setIsOverlayLoader
      );
    }
  };

  const openRejectModal = () => {
    let headingMsg = "Confirmation!";
    let titleMsg = "";

    headingMsg = "Confirmation!";
    titleMsg = "Do you really want to reject?";
    return (
      <RejectPopupModal
        isOpen={isRejectPopupOpen}
        setRemarkText={setRemarkText}
        remarkText={remarkText}
        handleClose={setIsRejectPopupOpen}
        handleConfirm={(e) => rejectHandleer(e)}
        popupMessage={titleMsg}
        popupHeading={headingMsg}
      />
    );
  };
  const [kpiValue, setKpiValue] = useState("");
  useEffect(() => {
    if (formConfig.listView && kpiValue !== null && kpiValue !== undefined) {
      getAllListingData();
    }
  }, [kpiValue]);
  const getAllListingData = async (
    pagination = "",
    fieldName = "",
    sortType = "",
    searchValue = "",
    isClearBtn = ""
  ) => {
    try {
      let endPoint =
        ["LC", "SLC"].includes(user?.role) && listModuleType === "allotment"
          ? formConfig?.apiEndpoints?.readUserWise
          : formConfig?.apiEndpoints?.read;
      if (!endPoint) {
        return;
      }
      let querystring = "";
      if (pagination) {
        querystring += "?page=" + pagination;
      }
      if (fieldName && sortType) {
        querystring += querystring
          ? "&sort_by=" + fieldName
          : "?sort_by=" + fieldName;
        querystring += "&sort_order=" + sortType;
      }
      if (kpiValue) {
        querystring += querystring
          ? "&kpi_status=" + kpiValue
          : "?kpi_status=" + kpiValue;
      }
      if (searchValue || searchValue === -1) {
        searchValue = searchValue === -1 ? "" : searchValue;
        querystring += querystring
          ? "&search=" + searchValue
          : "?search=" + searchValue;
      } else if (searchTerm) {
        querystring += querystring
          ? "&search=" + searchTerm
          : "?search=" + searchTerm;
      }
      if (!isClearBtn && searchFormData) {
        querystring = querystring || "?search=";

        for (let index = 1; index <= filterIndex; index++) {
          if (searchFormData?.["fieldWiseFilter_" + index]) {
            let fieldName =
              searchFormData?.["fieldWiseFilter_" + index] +
              "__" +
              searchFormData?.["fieldWiseFilterOption_" + index];
            querystring +=
              "&" +
              fieldName +
              "=" +
              searchFormData?.["fieldWiseFilterValue_" + index];
          }
        }
      }
      setLoadingTable(true);

      const res = await getDataFromApi(endPoint + querystring);
      if (res?.data?.status === 200) {
        setResponse(res.data);
        setLoadingTable(false);
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoadingTable(false);

      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoadingTable(false);
    }
  };
  const getAllListingDataExports = async () => {
    try {
      let endPoint = formConfig?.apiEndpoints?.exportExcel;
      if (!endPoint) {
        return;
      }
      let querystring = "";
      if (searchTerm) {
        querystring += querystring
          ? "&search=" + searchTerm
          : "?search=" + searchTerm;
      }
      if (searchFormData) {
        querystring = querystring || "?search=";

        for (let index = 1; index <= filterIndex; index++) {
          if (searchFormData?.["fieldWiseFilter_" + index]) {
            let fieldName =
              searchFormData?.["fieldWiseFilter_" + index] +
              "__" +
              searchFormData?.["fieldWiseFilterOption_" + index];
            querystring +=
              "&" +
              fieldName +
              "=" +
              searchFormData?.["fieldWiseFilterValue_" + index];
          }
        }
      }
      setLoadingTable(true);
      let res = await postDataFromApi(endPoint + querystring, "", "", 1);
      if (res?.status === 200) {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const excelFileName = listModuleType + ".xlsx"; // Change the extension to .xls if needed
        saveAs(blob, excelFileName);
        setLoadingTable(false);
      } else {
        setLoadingTable(false);
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoadingTable(false);

      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getAllStatusCount = async () => {
    try {
      const endPoint = formConfig?.apiEndpoints?.statuCount;
      const res = await getDataFromApi(endPoint);
      if (res?.data?.status === 200) {
        setStatusCounts(res.data.data);
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getAllotmentDetails = async () => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    const id = decryptDataForURL(params.get("sampleAllotmentId"));
    let bodyToPass = {
      sa_id: id,
    };
    try {
      let res = await postDataFromApi(getSimgleAllotmentDetailsApi, bodyToPass);
      if (res?.data?.status === 200) {
        let actulaResponse = res.data.data;
        actulaResponse.jrf_commodity =
          actulaResponse?.commodity_detail?.cmd_name;
        actulaResponse.sv_verifiedby =
          actulaResponse?.sample_allotedto_data?.usr_id;
        actulaResponse.sv_dateofverification =
          actulaResponse?.sa_actualdateofreporting;
        actulaResponse.sv_verifiedName =
          actulaResponse?.sample_allotedto_data?.first_name +
          " " +
          actulaResponse?.sample_allotedto_data?.last_name;
        setFormData({
          0: actulaResponse,
        });
      }
    } catch (error) {}
  };
  const getVerificationDetails = async (id = "", from = "") => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    if (!id) {
      id = decryptDataForURL(params.get("sampleVarificationId"));
    }
    let bodyToPass = {
      sv_id: id,
    };
    try {
      let res = await postDataFromApi(sampleverificationSingleApi, bodyToPass);

      if (res?.data?.status === 200) {
        let actulaResponse = res.data.data;
        actulaResponse.jrf_commodity =
          actulaResponse?.commodity_detail?.cmd_name;
        actulaResponse.sv_verifiedName = actulaResponse?.sv_verifiedby_name;
        actulaResponse.sa_remarks =
          actulaResponse?.allotment_detail?.sa_remarks;
        actulaResponse.sv_dateofverification =
          actulaResponse?.sv_dateofverification
            ? actulaResponse?.sv_dateofverification
            : actulaResponse?.allotment_detail?.sa_actualdateofreporting;
        const svDetailsfilter = actulaResponse.sv_detail.filter((svLab) => {
          svLab.svd_smplweight = svLab.svd_smplweight.replace("/", "");
          svLab.svd_smplweight = svLab.svd_smplweight.replace(" ", "");
          return true;
        });
        actulaResponse.sv_detail = svDetailsfilter;
        setTestMemoId(actulaResponse?.allotment_detail?.fk_testmemo_id);
        setTabOpen(true);
        setIstavSaveClicked(true);
        if (from) {
          const updatedFormData = { ...formData };
          updatedFormData[0]["sv_detail"] = svDetailsfilter;
          setFormData(updatedFormData);
        } else {
          setFormData({
            0: actulaResponse,
          });
        }
      }
    } catch (error) {}
  };
  const getSingleData = async () => {
    setIsOverlayLoader(true);
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    let status = params.get("status") ? params.get("status") : "";

    status = decryptDataForURL(status).toLowerCase();
    const id = decryptDataForURL(params.get("id"));
    const sampleInwardId = decryptDataForURL(params.get("sampleInwardId"));
    let actioning = decryptDataForURL(params.get("action"));
    if (
      sampleInwardId &&
      (status === "assignment" || status === "assignment-view")
    ) {
      getSampleIdsMasterData(sampleInwardId);
    }

    setAction(actioning);

    setJrfId(id);

    setPageType(status);
    if (status !== "inward") {
      setTabOpen(true);
    }

    if (status === "edit" || vieableArr.includes(status)) {
      if (vieableArr.includes(status)) {
        setViewOnly(true);
      }

      let bodyToPass = {
        jrf_id: id,
        model_name: "jrf_detail",
      };
      try {
        setIsOverlayLoader(true);
        let res = await postDataFromApi(
          formConfig.apiEndpoints.read,
          bodyToPass
        );
        if (res?.data?.status === 200) {
          let options = [];
          const jrfData = res.data.jrf;
          setFormData({
            0: jrfData,
            1: jrfData,
          });
          if (status === "edit") {
            if (
              jrfData.jrf_status !== "saved" &&
              jrfData.jrf_status !== "rejected"
            ) {
              setViewOnly(true);
            }
          }
          if (jrfData.jrf_status === "saved" && jrfData.jrf_is_ops) {
            getJRFOperationData(
              jrfData?.fk_jiid,
              jrfData?.fk_jisid,
              setSubTableData
            );
          }
          if (status === "checklist" && jrfData.jrf_status == "posted") {
            setFormData((prevFormData) => {
              return {
                ...prevFormData,
                0: {
                  ...prevFormData[0],
                  jrf_commodity: res.data.jrf?.jrf_commodity_detail?.cmd_name,
                  jrf_company: res.data.jrf?.jrf_company_detail?.cmp_id,
                  jrf_company_name: res.data.jrf?.jrf_company_detail?.cmp_name,
                  cmp_address: res.data.jrf?.jrf_branch_detail?.br_address,
                  jrf_lab: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_lab_detail?.lab_name +
                      "-" +
                      res.data.jrf?.jrf_lab_detail?.lab_code
                    : res.data.jrf?.jrf_lab_detail?.lab_id,
                  fk_sub_commodity: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_name
                    : res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_id,
                  jrf_branch_name: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_branch_detail?.br_code
                    : res.data.jrf?.jrf_branch_detail?.br_id,
                },
                1: {
                  ...prevFormData[1],
                  sampleInwardIdMain: sampleInwardId || "",
                  jrf_checklist: [
                    "Customer Name",
                    "Sample Source",
                    "Seal Number",
                    "Commodity",
                    "Test Method",
                  ],
                },
              };
            });
          } else {
            setFormData((prevFormData) => {
              return {
                ...prevFormData,
                0: {
                  ...prevFormData[0],

                  jrf_commodity: res.data.jrf?.jrf_commodity_detail?.cmd_name,

                  jrf_company: res.data.jrf?.jrf_company_detail?.cmp_id,
                  jrf_company_name: res.data.jrf?.jrf_company_detail?.cmp_name,
                  cmp_address: res.data.jrf?.jrf_branch_detail?.br_address,
                  jrf_lab: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_lab_detail?.lab_name +
                      "-" +
                      res.data.jrf?.jrf_lab_detail?.lab_code
                    : res.data.jrf?.jrf_lab_detail?.lab_id,
                  fk_sub_commodity: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_name
                    : res.data.jrf?.jrf_sub_commodity_detail?.sub_cmd_id,
                  jrf_branch_name: vieableArr.includes(status)
                    ? res.data.jrf?.jrf_branch_detail?.br_code
                    : res.data.jrf?.jrf_branch_detail?.br_id,
                  smpl_status: "To be generated",
                  smpl_inwrd_No: "To be generated",
                  jrf_contact_person:
                    jrfData.jrf_contact_person ||
                    user.logged_in_user_info.contact_person_name,
                  jrf_contact_person_number:
                    jrfData.jrf_contact_person_number ||
                    user.logged_in_user_info.contact_person_number,
                },
                1: {
                  ...prevFormData[1],
                  sampleInwardIdMain: sampleInwardId || "",
                },
              };
            });
          }

          if (moduleType == "GroupAssignment") {
            getAssignmentMasterData(
              res.data.jrf.jrf_commodity_detail.cmd_id,
              res.data.jrf.jrf_lab_detail.lab_id,
              GetTenantDetails(1, 1) == "TPBPL" ? "parameter" : "group"
            );
          }
          setReferenceData(jrfData.jrf_commodity_detail);
          jrfData.jrf_commodity_detail.sub_commodities.map((singleComm, i) =>
            options.push({
              id: singleComm.sub_cmd_id,
              name: singleComm.sub_cmd_name,
            })
          );
          setSubCommodityOptions(options);
          setLoading(true);
          setIsRefrenceNoCalled(false);
          setTimeout(() => {
            setLoading(false);
            setLoadingTable(false);
            setIsRefrenceNoCalled(true);
          }, 1000);
        }
      } catch (error) {
        setLoading(false);
        setLoadingTable(false);
      } finally {
        setLoading(false);
        setLoadingTable(false);
        setIsOverlayLoader(false);
      }
    }
    if (actioning === "View" || actioning === "Edit") {
      setTabOpen(true);
    }
    setIsOverlayLoader(false);
  };

  //For Reference Number Logic....

  async function referenceLogic(fieldName, value) {
    if (fieldName === "jrf_referenceno") {
      const ref_regex = /^(?=.*\d)(?=.*[A-Za-z])[\dA-Za-z]{16}$/;

      if (ref_regex.test(value)) {
        setReferenceNo(value);
      } else {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              jrf_state: "--",
              jrf_commodity: "--",
              jrf_mode: "--",
            },
          };
        });
      }
    }
  }
  const handleFieldChange = (
    sectionIndex,
    fieldName,
    value,
    type = "",
    isChecked = ""
  ) => {
    referenceLogic(fieldName, value);
    if (type) {
      value = isChecked;
    }
    if (fieldName === "jrf_referenceno") {
      value = value ? value.toUpperCase() : "";
    }
    if (moduleType == "internalcertificate") {
      if (
        fieldName == "ic_smpldrawnbylab" &&
        value == "Sample Not Drawn By Labrotory"
      ) {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              ic_locationofsmpl: "N/A",
              ic_samplingmethods: "N/A",
              ic_envcondition: "N/A",
              ic_conditionofsmpl: "N/A",
              ic_dos: "N/A",
            },
          };
        });
      } else if (
        fieldName == "ic_smpldrawnbylab" &&
        value == "Sample Drawn By Labrotory"
      ) {
        if (formData[0].ic_smpldrawnbylab === "Sample Not Drawn By Labrotory") {
          setFormData((prevFormData) => {
            return {
              ...prevFormData,
              0: {
                ...prevFormData[0],
                ic_locationofsmpl: "",
                ic_samplingmethods: "",
                ic_envcondition: "",
                ic_conditionofsmpl: "",
                ic_dos: "",
              },
            };
          });
        }
        getAssignmentMasterData(
          formData[0].cmd_id,
          formData[0].lab_id,
          "parameter",
          setIstestMethods
        );
      } else if (fieldName == "ic_ulrno") {
        checkICULRNoAvailibility(value, setIsOverlayLoader, setIsValideValue);
      }
    } else if (moduleType == "sfm") {
      if (fieldName === "sfm_dateanalysisstarted") {
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            [sectionIndex]: {
              ...prevFormData[sectionIndex],
              ["sfm_dateanalysiscompleted"]: "",
            },
          };
        });
      }
    }
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [sectionIndex]: {
          ...prevFormData[sectionIndex],
          [fieldName]: value,
        },
      };
    });

    // Clear error message when the user starts typing in the field
    setFormErrors((prevFormErrors) => {
      const newFormErrors = { ...prevFormErrors };
      if (newFormErrors[sectionIndex]) {
        delete newFormErrors[sectionIndex][fieldName];
        if (Object.keys(newFormErrors[sectionIndex]).length === 0) {
          delete newFormErrors[sectionIndex];
        }
      }
      return newFormErrors;
    });
  };

  const handleSubmit = () => {
    setActionClicked(true);
    console.log("formData", formData);

    let errors = {};
    const formDataObject = {};
    Object.entries(formData).forEach(([sectionIndex, fields]) => {
      Object.entries(fields).forEach(([fieldName, fieldValue]) => {
        formDataObject[fieldName] = fieldValue;
      });
    });
    let focusErrName = "";
    formConfigState.sections.forEach((section, sectionIndex) => {
      if (!["tabs", "sampleAssignmentTabs"].includes(section.type)) {
        section.fields.forEach((field) => {
          const { required, validation, pattern, type } = field;
          let value = formData[sectionIndex]?.[field.name];
          value = typeof value === "string" ? value.trim() : value;
          if (
            required &&
            (!value || value === "") &&
            field.type !== "label" &&
            field.type !== "checkbox" &&
            !field.multiple
          ) {
            if (!focusErrName) {
              focusErrName = field.name;
            }

            errors = {
              ...errors,
              [sectionIndex]: {
                ...errors[sectionIndex],
                [field.label]: validation
                  ? validation.message
                  : "This field is required",
              },
            };
          } else if (type === "phone") {
            if (!isValidPhoneNumber(value)) {
              if (!focusErrName) {
                focusErrName = field.name;
              }

              errors = {
                ...errors,
                [sectionIndex]: {
                  ...errors[sectionIndex],
                  [field.label]: validation
                    ? validation.message
                    : "This field is required",
                },
              };
            }
          } else if (pattern) {
            let regex;
            if (type === "tel") {
              regex = /^(?:\+91)?\d{10}$/;
            } else {
              regex = new RegExp(pattern);
            }
            if (!regex.test(value)) {
              if (!focusErrName) {
                focusErrName = field.name;
              }

              errors = {
                ...errors,
                [sectionIndex]: {
                  ...errors[sectionIndex],
                  [field.label]: validation
                    ? validation.message
                    : "This field is required",
                },
              };
            }
          } else if (
            (field.name === "jrf_terms_and_conditions" &&
              (!value || value === "")) ||
            (field.type === "select" &&
              field.multiple &&
              value &&
              value.length === 0)
          ) {
            if (!focusErrName) {
              focusErrName = field.name;
            }
            errors = {
              ...errors,
              [sectionIndex]: {
                ...errors[sectionIndex],
                [field.name]: validation
                  ? validation.message
                  : "This field is required",
              },
            };
          }
        });
      }
    });
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      const errorFieldElement = document.getElementById(`${focusErrName}`);
      if (errorFieldElement) {
        errorFieldElement.focus(); // Focus on the field with the first error
        errorFieldElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }); // Scroll to it
      }
      console.log("errors", errors);
      setFormErrors(errors);
      return false;
    }
  };
  //For Operation Certificate.
  const handleCertificateSave = async () => {
    const payload = {
      report_configuration: {
        rpc_from_date: formData?.[1]?.rpc_from_date,
        rpc_to_date: formData?.[1]?.rpc_to_date,
        rpc_is_lot_no: formData?.[1]?.rpc_is_lot_no?.length > 0 ? true : false,
        rpc_is_smpl_qty:
          formData?.[1]?.rpc_is_smpl_qty?.length > 0 ? true : false,
        rpc_is_total_no:
          formData?.[1]?.rpc_is_total_no?.length > 0 ? true : false,
        rpc_is_qty: formData?.[1]?.rpc_is_qty?.length > 0 ? true : false,
        rpc_is_smpl_wghtorunit:
          formData?.[1]?.rpc_is_smpl_wghtorunit?.length > 0 ? true : false,
        rpc_is_total_qty:
          formData?.[1]?.rpc_is_total_qty?.length > 0 ? true : false,
        rpc_is_smpl_mark:
          formData?.[1]?.rpc_is_smpl_mark?.length > 0 ? true : false,
        rpc_is_smpl_type:
          formData?.[1]?.rpc_is_smpl_type?.length > 0 ? true : false,
        rpc_is_smpl_total:
          formData?.[1]?.rpc_is_smpl_total?.length > 0 ? true : false,
        rpc_is_dos: formData?.[1]?.rpc_is_dos?.length > 0 ? true : false,
        rpc_is_wght_avg:
          formData?.[1]?.rpc_is_wght_avg?.length > 0 ? true : false,
        fk_jiid: formData[0]?.ji_id,
        fk_jisid: OperationTypeID,
      },
    };
    console.log("payload", payload, formData, OperationTypeID);

    // let res = await postDataFromApi("/report-config/create/", payload);
    // if (res?.data && res?.data?.status === 200) {
    //   setTabOpenSecond(true);
    //   toast.success(res?.data?.message, {
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });

    // } else {
    //   toast.error(res?.message, {
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }
  };

  const addRow = (tab, sectionIndex) => {
    setFormConfigState((prevFormConfig) => {
      const newFormConfig = { ...prevFormConfig };
      const section = newFormConfig.sections[sectionIndex];
      if (section.type === "tabs") {
        // Find the active tab
        const activeTabIndex = parseInt(activeTab.split("-")[1]);
        const activeTabData = section.tabs[activeTabIndex];

        // Clone the existing rows of the active tab
        const clonedRows = activeTabData.rows.map((row) => [...row]);

        // Copy properties from the first row and add the new row
        const newRow = clonedRows[0].map((property) => ({ ...property }));
        clonedRows.push(newRow);

        // Update the rows of the active tab
        activeTabData.rows = clonedRows;

        // Set newFormConfig to trigger re-render
        return { ...newFormConfig };
      }
      return newFormConfig;
    });
  };
  const addColumn = (tab, sectionIndex) => {
    setFormConfigState((prevFormConfig) => {
      const newFormConfig = { ...prevFormConfig };
      const section = newFormConfig.sections[sectionIndex];
      if (section.type === "tabs") {
        // Find the active tab
        const activeTabIndex = parseInt(activeTab.split("-")[1]); // Extract tab index from activeTab
        const activeTabData = section.tabs[activeTabIndex];

        // Clone the existing rows of the active tab
        const clonedRows = activeTabData.rows.map((row) => [...row]);
        const Placeholder = "New Column";

        // Iterate over each row and add a new column (text field) to it
        clonedRows.forEach((row) => {
          // Add an empty text field as a new column to each row
          row.push({ type: "text", value: "", placeholder: Placeholder }); // Here, we assume each column has a 'type' and 'value' property
        });

        // Update the rows of the active tab
        activeTabData.rows = clonedRows;

        // Set newFormConfig to trigger re-render
        return { ...newFormConfig };
      }
      return newFormConfig;
    });
  };

  const deleteRow = (sectionIndex) => {
    setFormConfigState((prevFormConfig) => {
      const newFormConfig = { ...prevFormConfig };

      // Access the correct section based on sectionIndex
      const section = newFormConfig.sections[sectionIndex];

      // Check if the section type is 'tabs'
      if (section.type === "tabs") {
        // Iterate through the tabs
        section.tabs.forEach((tab) => {
          // Check if the tab type is 'table'
          if (tab.type === "table" || tab.type === "tableadv") {
            // Check if there's more than one row
            if (tab.rows.length > 1) {
              // Remove the last row
              tab.rows.pop();
            }
          }
        });
      }

      return { ...newFormConfig };
    });
  };

  const deleteColumn = (sectionIndex, columnIndex) => {
    setFormConfigState((prevFormConfig) => {
      const newFormConfig = { ...prevFormConfig };
      const section = newFormConfig.sections[sectionIndex];

      // Find the tab with type 'table'
      const tableTab = section.tabs.find((tab) => tab.type === "table");

      if (tableTab) {
        // Remove the column from the headers array
        tableTab.headers.splice(columnIndex, 1);

        // Remove the corresponding cell from each row
        tableTab.rows.forEach((row) => row.splice(columnIndex, 1));
      }

      return newFormConfig;
    });
  };

  const handleAllSave = () => {
    const existingData = localStorage.getItem("allData");
    let newData = [];
    if (existingData) {
      newData = JSON.parse(existingData);
      if (!Array.isArray(newData)) {
        newData = [];
      }
    }
    newData.push(gaData);
    localStorage.setItem("allData", JSON.stringify(newData));
  };

  const handleCancel = () => {
    localStorage.removeItem("allData");
    navigate("/inwardList");
  };

  const handleSampleVerificationMainSubmit = async () => {
    let payload;
    payload = {
      sample_verification: {
        sv_dateofverification: formData[0].sv_dateofverification,
        fk_commodity_id: formData[0].fkey_commodity,
        sv_verifiedby: formData[0].sv_verifiedby,
        fk_allotmentid: formData[0].sa_id,
        status: "pending",
        tenant: GetTenantDetails(1),
      },
    };
    for (let obj in payload.sample_verification_detail) {
      if (
        payload["sample_verification_detail"][obj] === undefined ||
        !payload["sample_verification_detail"][obj]
      ) {
        let errLabel = "";
        toast.error("Date of Verification" + "  required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
        // }
      }
    }
    setIsOverlayLoader(true);
    let res = await postDataFromApi(sampleverificationCreateApi, payload);
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getVerificationDetails(res.data.data.sv_id);
      const currentURL = window.location.href;
      const newQueryString =
        "?status=" +
        encryptDataForURL("verification") +
        "&sampleVarificationId=" +
        encryptDataForURL(res.data.data.sv_id);
      const spbaseUrl = currentURL.split("?");
      let baseUrl = spbaseUrl[0];
      if (spbaseUrl.length > 2) {
        baseUrl = spbaseUrl[0] + spbaseUrl[1];
      }
      const newURL = baseUrl + newQueryString;
      window.history.replaceState({}, "", newURL);
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false);
  };

  const handleSFMMainSubmit = async () => {
    handleSubmit();
    let isValidate = handleSubmit();
    if (!isValidate) {
      return false;
    }
    let payload;
    payload = {
      sfm_id: formData[0].sfm_id,
      sfm_data: {
        sfm_expecteddateanalysis: formData[0].sfm_expecteddateanalysis,
        sfm_dateanalysisstarted: formData[0].sfm_dateanalysisstarted,
        sfm_dateanalysiscompleted: formData[0].sfm_dateanalysiscompleted,
        sfm_remarks: formData[0].sfm_remarks,
        sfm_ambient_temp: formData[0].sfm_ambient_temp,
        sfm_borometricpressure: formData[0].sfm_borometricpressure,
        sfm_humidity: formData[0].sfm_humidity,
        sfm_status: "in-process",
        tenant: GetTenantDetails(1),
      },
    };
    setIsOverlayLoader(true);
    let res = await putDataFromApi(SFMUpdateApi, payload);
    if (res.data && res.data.status === 200) {
      // handleAllotUpdate(formData)
      getSFMDetails(
        res.data.data.sfm_id,
        setFormData,
        setTabOpen,
        setIstavSaveClicked,
        setTestMemoSetData
      );
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false);
  };

  const searchAPI = async (value) => {
    try {
      setSearchTerm(value);
      if (value.length > 2 || value.length < 1) {
        if (value) {
          getAllListingData("", "", "", value);
        } else {
          getAllListingData("", "", "", -1);
        }
      } else {
        setLoading(false);
      }
    } catch {}
  };

  //Filterlogic Written here by sufiyan

  const handleSearchFilter = (e, type = "") => {
    const { name, value } = e.target;
    if (type) {
      setSearchFormDataType((prevFormData) => {
        return {
          ...prevFormData,
          [name]: e.target[e.target.selectedIndex].getAttribute("data-type"),
        };
      });
    }
    setSearchFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  /*
  Create by sufiyan 
  use for clrearing search filter

  */
  const clearFilterData = () => {
    setSearchFormData({});
    setSearchFormDataErros({});
    setSearchFormDataType({});
    setFilterIndex(1);
    setIsFilterBtnClicked(false);
    getAllListingData("", "", "", "", 1);
  };

  const handleAllotValidate = (e) => {
    let isValidate = handleSubmit();
    if (!isValidate) {
      return false;
    }
    setInwardBtnchange("allotment");
    setIsPopupOpen(true);
  };

  const handleAllotSubmit = async () => {
    let payload;
    payload = {
      // sa_id: formData[0].sa_id,
      sample_allotment: {
        sa_sampleallottedto: formData[0].sa_sampleallottedto,
        sa_actualdateofreporting: formData[0].sa_actualdateofreporting,
        sa_remarks: formData[0].sa_remarks,
        sa_expdateofresult: formData[0].sa_expdateofresult,
        fk_testmemo_id: testMemoId ? testMemoId : formData[0].fk_testmemo_id,
        fkey_commodity: formData[0].fkey_commodity,
        // status: GetTenantDetails(1, 1) == "TPBPL" ? "completed" : "allotted",
        status: "allotted",
        tenant: GetTenantDetails(1),
      },
    };
    let res;
    setIsOverlayLoader(true);
    if (formData[0].sa_id) {
      payload.sa_id = formData[0].sa_id;
      res = await putDataFromApi(allotmentUpdateApi, payload);
    } else {
      res = await postDataFromApi(allotmentCreateApi, payload);
    }
    if (res.data && res.data.status === 200) {
      if (!formData[0].sa_id && GetTenantDetails(1, 1) == "TPBPL") {
        await handleSFMCreateWithoutVerification(res.data.data);
      }
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        navigate("/allotmentList");
      }, 1000);
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false);
  };

  const handleBackButtonFunction = () => {
    let redirectURL = "";
    if (pageType === "inward" || pageType === "assignment") {
      redirectURL = "/inwardList";
    } else if (moduleType === "testmemomain") {
      redirectURL = "/testmemoList";
    } else if (moduleType == "internalcertificate") {
      redirectURL = "/testmemoList";
    } else if (moduleType == "allotment") {
      redirectURL = "/allotmentList";
    } else if (moduleType == "sfm") {
      redirectURL = "/SFMList";
    } else if (moduleType == "sampleverification") {
      redirectURL = "/verificationList";
    } else if (moduleType == "jobinstruction") {
      redirectURL = "/operation/jrfInstructionListing";
    } else {
      redirectURL = "/jrfListing";
    }
    navigate(redirectURL);
  };

  const getCustomCellValues = (cell) => {
    if (moduleType == "internalcertificate") {
      if (
        [
          "ic_samplingmethods",
          "ic_locationofsmpl",
          "ic_dos",
          "ic_envcondition",
        ].includes(cell.name)
      ) {
        if (
          formData[0]?.ic_smpldrawnbylab === "Sample Not Drawn By Labrotory"
        ) {
          cell.required = false;
          if (cell.name === "ic_dos") {
            cell.type = "text";
          }
          if (cell.name === "ic_samplingmethods") {
            cell.type = "text";
          }
        } else {
          cell.required = true;
          if (cell.name === "ic_dos") {
            cell.type = "date";
          }
          if (cell.name === "ic_samplingmethods") {
            if (!isTestMethods) {
              cell.type = "label";
            } else {
              cell.type = "select";
              cell.multiple = true;
              cell.options = [];
            }
          }
        }
      } else if (cell.name === "ic_ulrno") {
        if (formData[0]?.ic_id || formData[0].nonScopeData) {
          if (formData[0].nonScopeData || !formData[0]?.ic_ulrno) {
            cell.value = "-";
          }
          cell.type = "label";
          cell.required = false;
          cell.pattern = "";
        }
      }
    } else if (moduleType == "jrf") {
      if (cell.name == "jrf_lab") {
        if (user?.role === "LR" && viewOnly && !isExternalJRF) {
          cell.name = "jrf_branch_name";
          cell.label = "Branch Name";
        }
        if (isExternalJRF) {
          cell.disabled = true;
          cell.readOnly = true;
        }
      } else if (cell.name == "fk_sub_commodity") {
        cell.isCustomOptions = true;
        cell.customOptions = subCommodityOptions;
      } else if (cell.name == "jrf_test_method") {
        if (GetTenantDetails(1, 1) == "TPBPL") {
          cell.options = ["IS", "ISO", "ASTM", "SOP", "IP", "IEC"];
        }
      }
    } else if (moduleType == "sfm") {
      if (cell.name === "sfm_dateanalysiscompleted") {
        // cell.startDate = formData[0].sfm_dateanalysisstarted
        //   ? formData[0].sfm_dateanalysisstarted
        //   : "";
        cell.pastDate = false;
        cell.minDate = formData[0]?.sample_inward_dos;
      } else if (cell.name === "sfm_dateanalysisstarted") {
        cell.startDate = formData[0].sample_dateofverification
          ? formData[0].sample_dateofverification
          : "";
      } else if (cell.name === "sfm_dateanalysisstarted") {
        cell.startDate = formData[0].sample_dateofverification
          ? formData[0].sample_dateofverification
          : "";
      }
    } else if (moduleType == "jobinstruction") {
      if (cell.name === "fk_subcommodityid") {
        // console.log('subCommodityOptions', subCommodityOptions)
        // cell.isCustomOptions = true;
        // cell.customOptions = subCommodityOptions;
      } else if (cell.name === "fk_supplierid") {
        if (formData[0].ji_is_supplier === "Buyer") {
          cell.label = "Buyer";
        } else if (formData[0].ji_is_supplier === "Supplier") {
          cell.label = "Supplier";
        }
      }
    } else if (moduleType == "GroupAssignment") {
      if (cell.name == "cmp_address") {
        if (formData[0]?.jrf_is_external) {
          cell.name = "jrf_ext_address";
          cell.label = "External Customer Address";
        }
      } else if (cell.name == "jrf_company_name") {
        if (formData[0]?.jrf_is_external) {
          cell.name = "jrf_ext_orgnizationname";
          cell.label = "External Customer Name";
        }
      }
    } else if (moduleType == "inwardChecklist") {
      if (cell.name == "jrf_company_name") {
        if (formData[0]?.jrf_is_external) {
          cell.name = "jrf_ext_orgnizationname";
          cell.label = "External Customer Name";
        }
      } else if (cell.name == "jrf_pkging_condition") {
        if (GetTenantDetails(1, 1) == "TPBPL") {
          cell.options = [
            "Sealed",
            "Unsealed",
            "Contamination",
            "Sign of Damage (Puncture, Leaks)",
          ];
        }
      }
    } else if (formConfig?.sections[0]?.moduleType == "allotment") {
      if (cell.name === "sa_actualdateofreporting") {
        cell.pastDate = true;
        cell.minDate = formData[0]?.smpl_dos;
      }
    }
    return cell;
  };
  const tileSubHeader = [
    { Text: "Transportable moisture limit (TML)" },
    { Text: "Groups & Parameters" },
  ];

  const getTileClassName = (status, tile) => {
    //     blank ->step1
    // saved ->step2
    // created /pre-analysis - >step3
    // analysis -step4

    if (
      (status === "" || status === undefined) &&
      tile.Text === "Company & Commodity"
    ) {
      return "card_header_btn_active";
    } else if (status === "saved" && tile.Text === "Scope of Work") {
      return "card_header_btn_active";
    } else if (
      (status === "created" || status === "pre-analysis") &&
      tile.Text === "Groups & Parameters"
    ) {
      return "card_header_btn_active";
    } else if (status === "analysis" && tile.Text === "Nominations Details") {
      return "card_header_btn_active";
    } else {
      return "card_header_btn";
    }
  };
  const getSubTileClassName = (section, tile) => {
    console.log(
      operationStepNo,
      tile.Text,
      operationStepNo == 3 && tile.Text === "Assign Parameters"
    );
    if (operationStepNo === 1 && tile.Text === "Groups & Parameters") {
      return "card_header_btn_active";
    } else if (operationStepNo === 2 && tile.Text === "Sample Preparation") {
      return "card_header_btn_active";
    } else if (operationStepNo == 3 && tile.Text === "Assign Parameters") {
      return "card_header_btn_active";
    } else if (operationStepNo == 4 && tile.Text === "Size Analysis") {
      return "card_header_btn_active";
    } else if (operationStepNo == 5 && tile.Text === "Send to JRF / Oth. TPI") {
      return "card_header_btn_active";
    } else if (section?.tabs[0]?.label === tile.Text) {
      return "card_header_btn_active";
    } else {
      return "card_header_btn";
    }
  };
  ///For Main Submission...
  return !loading ? (
    <form key={"Main-Form"}>
      {/* <form onSubmit={handleSubmit}> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isOverlayLoader && <OverlayLoading />}

      {formConfig.sections?.map((section, sectionIndex) =>
        ["tabs"].includes(section.type) ? (
          tabOpen ? (
            <div key={"form-section" + sectionIndex}>
              <div className="card_header_btns card_header_btns_tabs">
                {section.tabs[0]?.tileSubHeader?.map((tile, tileIndex) => {
                  return (
                    <button
                      type="button"
                      className={getSubTileClassName(section, tile)}
                    >
                      <span className="extra-text">{tile.Text}</span>
                    </button>
                  );
                })}

                {/* <button type="button">

                  <span className="extra-text">Groups & Parameters</span>
                </button> */}
              </div>
              <div
                className={
                  "nav nav-tabs nav-pills nav-fill " +
                  (section.tabs[0]?.tileSubHeader?.length > 0 &&
                    "nav-tabs_hidden")
                }
                // className={
                //   "nav nav-tabs nav-pills nav-fill "
                // }
              >
                {section.tabs.map((tab, tabIndex) => {
                  if (tab.type === "tablePreview") {
                    if (
                      (action === "View" || !tab.isSubPreview) &&
                      (!tab.isSubPreview ||
                        [
                          "assigning",
                          "assigned",
                          "tm-created",
                          "sent to lab",
                          "certified",
                        ].includes(formData[0]?.smpl_status) ||
                        (tab.isSubPreview && moduleType === "jobinstruction"))
                    ) {
                      return (
                        <NavItem key={"section-tab" + tabIndex}>
                          <NavLink
                            className={classnames("nav-link tab_header", {
                              active:
                                activeTab === `${sectionIndex}-${tabIndex}`,
                            })}
                            onClick={() =>
                              tab.isNotComponene
                                ? null
                                : setActiveTab(`${sectionIndex}-${tabIndex}`)
                            }
                            tabIndex="0"
                            href="#"
                          >
                            {tab.label}
                          </NavLink>
                        </NavItem>
                      );
                    }
                  } else {
                    return (
                      tab.type !== "tableList" && (
                        <NavItem key={"section-tab" + tabIndex}>
                          <NavLink
                            className={classnames("nav-link tab_header", {
                              active:
                                activeTab === `${sectionIndex}-${tabIndex}`,
                            })}
                            onClick={() =>
                              tab.isNotComponene
                                ? null
                                : setActiveTab(`${sectionIndex}-${tabIndex}`)
                            }
                            tabIndex="0"
                            href="#"
                          >
                            {tab.label}
                          </NavLink>
                        </NavItem>
                      )
                    );
                  }
                })}
              </div>
              <div className="tab-content">
                {section.tabs.map((tab, tabIndex) => (
                  <div
                    key={"tab-content" + tabIndex}
                    role="tabpanel"
                    className={classnames("tab-pane", {
                      active: activeTab === `${sectionIndex}-${tabIndex}`,
                    })}
                  >
                    {/* Render tab content here */}
                    <Row>
                      <Col>
                        {tab.isNotComponene ? null : tab.type === "table" ? (
                          // tab.headers.length < 5 ? (
                          <span>
                            {
                              <Popup
                                showModal={showModal}
                                setShowModal={setShowModal}
                                tab={tab}
                                sectionIndex={sectionIndex}
                                formData={formData}
                                handleFieldChange={handleFieldChange}
                                formErrors={formErrors}
                                addRow={addRow}
                              />
                            }
                            {formData[0].jrf_is_ops &&
                            moduleType === "sampleinward" ? (
                              <RenderTableOperationSection
                                key={`${sectionIndex}-${tabIndex}`}
                                section={tab}
                                sectionIndex={sectionIndex}
                                formData={formData}
                                handleFieldChange={handleFieldChange}
                                formErrors={formErrors}
                                addRow={() => {
                                  tab.headers.length < 6 ||
                                  pageType === "inward" ||
                                  pageType === "assignment"
                                    ? addRow(tab, sectionIndex)
                                    : setShowModal(true);
                                }}
                                deleteRow={() => deleteRow(sectionIndex)}
                                deleteColumn={(columnIndex) =>
                                  deleteColumn(sectionIndex, columnIndex)
                                }
                                setFormData={setFormData}
                                popupMessages={formConfig.popupMessages}
                                pageType={pageType}
                                action={action || tab.isViewOnly}
                                masterOptions={masterResponse}
                                actionClicked={actionClicked}
                                setSaveClicked={setSaveClicked}
                                saveClicked={saveClicked}
                                setTableData={setSubTableData}
                                tableData={subTableData}
                                moduleType={moduleType}
                                pageType2={true}
                                simpleInwardResponse={simpleInwardResponse}
                                setSimpleInwardResponse={
                                  setSimpleInwardResponse
                                }
                                groupDefaultValue={groupDefaultValue}
                                testMemoId={testMemoId}
                                getVerificationDetails={getVerificationDetails}
                                getSampleIdsMasterData={getSampleIdsMasterData}
                                setIsOverlayLoader={setIsOverlayLoader}
                                isOverlayLoader={isOverlayLoader}
                                operationName={operationName}
                                OperationType={OperationType}
                                OperationTypeID={OperationTypeID}
                              />
                            ) : (
                              <RenderTableSection
                                key={`${sectionIndex}-${tabIndex}`}
                                section={tab}
                                sectionIndex={sectionIndex}
                                formData={formData}
                                handleFieldChange={handleFieldChange}
                                formErrors={formErrors}
                                addRow={() => {
                                  tab.headers.length < 6 ||
                                  pageType === "inward" ||
                                  pageType === "assignment"
                                    ? addRow(tab, sectionIndex)
                                    : setShowModal(true);
                                }}
                                deleteRow={() => deleteRow(sectionIndex)}
                                deleteColumn={(columnIndex) =>
                                  deleteColumn(sectionIndex, columnIndex)
                                }
                                setFormData={setFormData}
                                popupMessages={formConfig.popupMessages}
                                pageType={pageType}
                                action={action || tab.isViewOnly}
                                masterOptions={masterResponse}
                                actionClicked={actionClicked}
                                setSaveClicked={setSaveClicked}
                                saveClicked={saveClicked}
                                setTableData={setSubTableData}
                                tableData={subTableData}
                                moduleType={moduleType}
                                pageType2={true}
                                simpleInwardResponse={simpleInwardResponse}
                                setSimpleInwardResponse={
                                  setSimpleInwardResponse
                                }
                                groupDefaultValue={groupDefaultValue}
                                testMemoId={testMemoId}
                                getVerificationDetails={getVerificationDetails}
                                getSampleIdsMasterData={getSampleIdsMasterData}
                                setIsOverlayLoader={setIsOverlayLoader}
                                isOverlayLoader={isOverlayLoader}
                                operationName={operationName}
                                OperationType={OperationType}
                                OperationTypeID={OperationTypeID}
                                operationStepNo={operationStepNo}
                              />
                            )}
                          </span>
                        ) : tab.type === "manualTable" ? (
                          // tab.headers.length < 5 ? (
                          <span>
                            {
                              <Popup
                                showModal={showModal}
                                setShowModal={setShowModal}
                                tab={tab}
                                sectionIndex={sectionIndex}
                                formData={formData}
                                handleFieldChange={handleFieldChange}
                                formErrors={formErrors}
                                addRow={addRow}
                              />
                            }
                            <RenderTableSetAllManualSection
                              key={`${sectionIndex}-${tabIndex}`}
                              section={tab}
                              tabIndex={tabIndex}
                              sectionIndex={sectionIndex}
                              formData={formData}
                              handleFieldChange={handleFieldChange}
                              formErrors={formErrors}
                              addRow={() => {
                                tab.headers.length < 6 ||
                                pageType === "inward" ||
                                pageType === "assignment"
                                  ? addRow(tab, sectionIndex)
                                  : setShowModal(true);
                              }}
                              deleteRow={() => deleteRow(sectionIndex)}
                              deleteColumn={(columnIndex) =>
                                deleteColumn(sectionIndex, columnIndex)
                              }
                              setFormData={setFormData}
                              popupMessages={formConfig.popupMessages}
                              pageType={pageType}
                              action={action}
                              masterOptions={masterResponse}
                              actionClicked={actionClicked}
                              setSaveClicked={setSaveClicked}
                              saveClicked={saveClicked}
                              setTableData={setSubTableData}
                              tableData={subTableData}
                              moduleType={formConfig?.sections[0]?.moduleType}
                              pageType2={true}
                              simpleInwardResponse={simpleInwardResponse}
                              setSimpleInwardResponse={setSimpleInwardResponse}
                              groupDefaultValue={groupDefaultValue}
                              testMemoId={testMemoId}
                              getVerificationDetails={getVerificationDetails}
                              getSampleIdsMasterData={getSampleIdsMasterData}
                              setIsOverlayLoader={setIsOverlayLoader}
                              isOverlayLoader={isOverlayLoader}
                              viewOnly={viewOnly}
                              OperationType={OperationType}
                              OperationTypeID={OperationTypeID}
                              operationStepNo={operationStepNo}
                            />
                          </span>
                        ) : tab.type === "draftSurveyTable" ? (
                          // tab.headers.length < 5 ? (
                          <span>
                            {
                              <Popup
                                showModal={showModal}
                                setShowModal={setShowModal}
                                tab={tab}
                                sectionIndex={sectionIndex}
                                formData={formData}
                                handleFieldChange={handleFieldChange}
                                formErrors={formErrors}
                                addRow={addRow}
                              />
                            }
                            <RenderTableForDraftSurveySection
                              key={`${sectionIndex}-${tabIndex}`}
                              section={tab}
                              sectionIndex={sectionIndex}
                              formData={formData}
                              handleFieldChange={handleFieldChange}
                              setFormData={setFormData}
                            />
                          </span>
                        ) : tab.type === "tableadv" ? (
                          <RenderAdvTableSection
                            key={`${sectionIndex}-${tabIndex}`}
                            section={tab}
                            sectionIndex={sectionIndex}
                            formData={formData}
                            handleFieldChange={handleFieldChange}
                            formErrors={formErrors}
                            addRow={() => {
                              true
                                ? addRow(tab, sectionIndex)
                                : setShowModal(true);
                            }}
                            addColumn={() => addColumn(tab, sectionIndex)}
                            deleteRow={() => deleteRow(sectionIndex)}
                            deleteColumn={(columnIndex) =>
                              deleteColumn(sectionIndex, columnIndex)
                            }
                            groupAssignment={tab.groupAssignment}
                            handleAllSave={handleAllSave}
                            handleCancel={handleCancel}
                            gaData={gaData}
                            setGaData={setGaData}
                            showModalGA={showModalGA}
                            setShowModalGA={setShowModalGA}
                            pageType={pageType}
                            actionClicked={actionClicked}
                          />
                        ) : tab.type === "tablePreview" ? (
                          (action === "View" || !tab.isSubPreview) &&
                          (!tab.isSubPreview ||
                            [
                              "assigning",
                              "assigned",
                              "tm-created",
                              "sent to lab",
                              "certified",
                            ].includes(formData[0]?.smpl_status) ||
                            (tab.isSubPreview &&
                              moduleType === "jobinstruction") ||
                            tab.moduleType === "JRFOperationAssignment") && (
                            <RenderTablePreview
                              key={`${sectionIndex}-${tabIndex}`}
                              section={tab}
                              sectionIndex={sectionIndex}
                              formData={formData}
                              handleFieldChange={handleFieldChange}
                              formErrors={formErrors}
                              addRow={() => {
                                tab.headers.length < 6 ||
                                pageType === "inward" ||
                                pageType === "assignment"
                                  ? addRow(tab, sectionIndex)
                                  : setShowModal(true);
                              }}
                              deleteRow={() => deleteRow(sectionIndex)}
                              deleteColumn={(columnIndex) =>
                                deleteColumn(sectionIndex, columnIndex)
                              }
                              setFormData={setFormData}
                              popupMessages={formConfig.popupMessages}
                              pageType={pageType}
                              action={action}
                              masterOptions={masterResponse}
                              actionClicked={actionClicked}
                              isSubPreview={tab.isSubPreview}
                              simpleInwardResponse={simpleInwardResponse}
                              setSimpleInwardResponse={setSimpleInwardResponse}
                              tabIndex={tabIndex}
                              moduleType={moduleType}
                              OperationTypeID={OperationTypeID}
                            />
                          )
                        ) : tab.type === "accordion" ? (
                          <div
                            key={"sectionIndex" + sectionIndex}
                            className="my-2 bg-white"
                          >
                            <Card>
                              <CardBody>
                                <Row>
                                  {tab.fields.map((field, fieldIndex) => (
                                    <div
                                      key={"Form-Accordion" + fieldIndex}
                                      className={"col-md-" + field.width}
                                    >
                                      <RenderFields
                                        field={field}
                                        sectionIndex={sectionIndex}
                                        fieldIndex={fieldIndex}
                                        formData={formData}
                                        handleFieldChange={handleFieldChange}
                                        formErrors={formErrors}
                                        ///For View Only
                                        viewOnly={viewOnly}
                                        actionClicked={actionClicked}
                                      />
                                    </div>
                                  ))}
                                  <RenderAccordionSection
                                    section={tab}
                                    sectionIndex={sectionIndex}
                                    formData={formData}
                                    handleFieldChange={handleFieldChange}
                                    formErrors={formErrors}
                                  />
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        ) : tab.type === "tableList" ? (
                          <RenderSubListSection
                            section={tab.listView}
                            sectionIndex={1}
                            actions={tab.listView.actions}
                            responseData={subTableData}
                            getAllListingData={getAllListingData}
                            formConfig={tab}
                            statusCounts={statusCounts}
                            setIsRejectPopupOpen={setIsRejectPopupOpen}
                            setJRFCreationType={setJrfCreationType}
                            setIsPopupOpen={setIsPopupOpen}
                            loadingTable={loadingTable}
                            setIsOverlayLoader={setIsOverlayLoader}
                            moduleType={moduleType}
                            formData={formData}
                          />
                        ) : (
                          <div
                            key={"sectionIndex" + sectionIndex}
                            className="my-2 bg-white"
                          >
                            <Card>
                              <CardBody>
                                {/* <CardTitle tag="h5">{section.title}</CardTitle> */}
                                {/*
                                    Author : Yash Darshankar
                                    Date : 21/06/2024
                                    Description: Added this Class for New UI Spacing design
                                */}
                                <Row className="main_form">
                                  {tab.fields.map((field, fieldIndex) => {
                                    let isShow = true;

                                    if (moduleType === "inwardChecklist") {
                                      if (
                                        field.name === "jrf_sample_condition" &&
                                        GetTenantDetails(1, 1) == "TPBPL"
                                      ) {
                                        isShow = false;
                                      } else if (
                                        field.name == "jrf_pkging_condition"
                                      ) {
                                        if (GetTenantDetails(1, 1) == "TPBPL") {
                                          field.options = [
                                            "Sealed",
                                            "Unsealed",
                                            "Contamination",
                                            "Sign of Damage (Puncture, Leaks)",
                                          ];
                                          // field.hintText = "<ul><li>All information is mutually confidential between TCRC Petrolabs and the customer. Any intention of placing the same in the public domain must be mutually agreed upon in print.</li><li>All proprietary information of TCRC Petrolabs and their customers is mutually agreed upon as confidential.</li><li>Tick (☑) whichever applies.</li></ul>"
                                        }
                                      }
                                    }
                                    return (
                                      isShow && (
                                        <div
                                          key={"Form-default" + fieldIndex}
                                          className={"col-md-" + field.width}
                                        >
                                          <RenderFields
                                            field={getCustomCellValues(field)}
                                            sectionIndex={sectionIndex}
                                            fieldIndex={fieldIndex}
                                            formData={formData}
                                            handleFieldChange={
                                              handleFieldChange
                                            }
                                            formErrors={formErrors}
                                            viewOnly={
                                              !vieableArr.includes(pageType) &&
                                              viewOnly
                                            }
                                          />
                                        </div>
                                      )
                                    );
                                  })}
                                </Row>
                                {formConfig?.sections[0]?.moduleType ===
                                  "inwardChecklist" &&
                                  GetTenantDetails(1, 1) == "TPBPL" && (
                                    <>
                                      <ul>
                                        <li>
                                          All information is mutually
                                          confidential between TCRC Petrolabs
                                          and the customer. Any intention of
                                          placing the same in the public domain
                                          must be mutually agreed upon in print.
                                        </li>
                                        <li>
                                          All proprietary information of TCRC
                                          Petrolabs and their customers is
                                          mutually agreed upon as confidential.
                                        </li>
                                        <li>Tick (☑) whichever applies.</li>
                                      </ul>
                                    </>
                                  )}
                              </CardBody>
                            </Card>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
              {section.label === "Test Memo" ? (
                <div className="tab_footer">
                  <p>
                    {rolesDetails.map((role, UserIndex) => (
                      <span key={"role-" + UserIndex}>
                        {user?.role === role?.role ? role.label : null}
                      </span>
                    ))}{" "}
                    <br />
                    <br />{" "}
                    <span>
                      {user?.logged_in_user_info?.contact_person_name}
                    </span>
                  </p>
                  {/* <p>
                    Technical Manager <br />
                    <br /> <span>Benjamin Thompson</span>
                  </p> */}
                </div>
              ) : null}
            </div>
          ) : null
        ) : section.type === "testMemoTabs" ? (
          tabOpen ? (
            <div key={"form-section" + sectionIndex}>
              <div className="nav nav-tabs nav-pills nav-fill">
                {testMemoSetData.map((tab, tabIndex) => {
                  return (
                    <React.Fragment key={"tabIndex" + tabIndex}>
                      <NavItem key={"section-tab" + tabIndex}>
                        <NavLink
                          className={classnames("nav-link tab_header", {
                            active: activeTab === `${sectionIndex}-${tabIndex}`,
                          })}
                          onClick={() =>
                            setActiveTab(`${sectionIndex}-${tabIndex}`)
                          }
                          tabIndex="0"
                          href="#"
                        >
                          {"Set " + (tabIndex + 1)}
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="tab-content">
                {testMemoSetData.map((tab, tabIndex) => (
                  <div
                    key={"tab-content" + tabIndex}
                    role="tabpanel"
                    className={classnames("tab-pane", {
                      active: activeTab === `${sectionIndex}-${tabIndex}`,
                    })}
                  >
                    <Row>
                      <Col>
                        <RenderAdvtestMemoTableSection
                          key={`${sectionIndex}-${tabIndex}`}
                          section={section}
                          tabIndex={tabIndex}
                          setData={tab}
                          sectionIndex={sectionIndex}
                          formData={formData}
                          handleFieldChange={handleFieldChange}
                          formErrors={formErrors}
                          addRow={() => {
                            true
                              ? addRow(tab, sectionIndex)
                              : setShowModal(true);
                          }}
                          addColumn={() => addColumn(tab, sectionIndex)}
                          deleteRow={() => deleteRow(sectionIndex)}
                          deleteColumn={(columnIndex) =>
                            deleteColumn(sectionIndex, columnIndex)
                          }
                          groupAssignment={tab.groupAssignment}
                          handleAllSave={handleAllSave}
                          handleCancel={handleCancel}
                          gaData={gaData}
                          setGaData={setGaData}
                          showModalGA={showModalGA}
                          setShowModalGA={setShowModalGA}
                          pageType={pageType}
                          actionClicked={actionClicked}
                          setIsOverlayLoader={setIsOverlayLoader}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
              {section.label === "Test Memo" ? (
                <div className="tab_footer">
                  <p>
                    {rolesDetails.map((role, UserIndex) => (
                      <span key={"role-" + UserIndex}>
                        {formData[0]?.tm_created_by?.role === role?.role
                          ? role.label
                          : null}
                      </span>
                    ))}{" "}
                    <br />
                    <br /> <span>{formData[0]?.tm_created_by?.name}</span>
                  </p>
                  <p>
                    {rolesDetails.map((role, UserIndex) => (
                      <span key={"role-" + UserIndex}>
                        {formData[0]?.technical_manager?.role === role?.role
                          ? role.label
                          : null}
                      </span>
                    ))}{" "}
                    <br />
                    <br /> <span>{formData[0]?.technical_manager?.name}</span>
                  </p>
                </div>
              ) : null}
            </div>
          ) : null
        ) : section.type === "sampleAssignmentTabs" ? (
          tabOpen ? (
            <div key={"form-section" + sectionIndex}>
              <div className="card_header_btns card_header_btns_tabs">
                {section.tabs[0]?.tileSubHeader?.map((tile, tileIndex) => {
                  return (
                    <button
                      type="button"
                      className={getSubTileClassName(section, tile)}
                    >
                      <span className="extra-text">{tile.Text}</span>
                    </button>
                  );
                })}
              </div>
              <div
                className={
                  "nav nav-tabs nav-pills nav-fill " +
                  (section.tabs[0]?.tileSubHeader?.length > 0 &&
                    "nav-tabs_hidden")
                }
              >
                <NavItem key={"section-tab"}>
                  <NavLink
                    className={classnames("nav-link tab_header", {
                      active: true,
                    })}
                    tabIndex="0"
                    href="#"
                  >
                    {section.label
                      ? section.label
                      : "Sample Group and Parameter List"}
                  </NavLink>
                </NavItem>
              </div>

              {section.tabs.map((tab, tabIndex) => (
                <Row key={"tabIndx" + tabIndex}>
                  <Col>
                    <RenderAssignmentTableSection
                      key={`${sectionIndex}-${tabIndex}`}
                      section={tab}
                      sectionIndex={sectionIndex}
                      formData={formData}
                      handleFieldChange={handleFieldChange}
                      formErrors={formErrors}
                      addRow={() => {
                        tab.headers.length < 6 ||
                        pageType === "inward" ||
                        pageType === "assignment"
                          ? addRow(tab, sectionIndex)
                          : setShowModal(true);
                      }}
                      deleteRow={() => deleteRow(sectionIndex)}
                      deleteColumn={(columnIndex) =>
                        deleteColumn(sectionIndex, columnIndex)
                      }
                      setFormData={setFormData}
                      popupMessages={formConfig.popupMessages}
                      pageType={pageType}
                      action={action}
                      masterOptions={masterResponse}
                      actionClicked={actionClicked}
                      setSaveClicked={setSaveClicked}
                      saveClicked={saveClicked}
                      setTableData={setSubTableData}
                      tableData={subTableData}
                      moduleType={moduleType}
                      pageType2={true}
                      simpleInwardResponse={simpleInwardResponse}
                      setSimpleInwardResponse={setSimpleInwardResponse}
                      groupDefaultValue={groupDefaultValue}
                      testMemoId={testMemoId}
                      getVerificationDetails={getVerificationDetails}
                      getSampleIdsMasterData={getSampleIdsMasterData}
                      getAssignmentMasterData={getAssignmentMasterData}
                      isDisplayNewAddOption={isDisplayNewAddOption}
                      setIsDisplayNewAddOption={setIsDisplayNewAddOption}
                      setIsOverlayLoader={setIsOverlayLoader}
                      isOverlayLoader={isOverlayLoader}
                      useForComponent={useForComponent}
                      OperationTypeID={OperationTypeID}
                      TMLID={TMLID}
                      OperationType={OperationType}
                      operationName={operationName}
                      editReordType={editReordType}
                      setJRFTPIFormData={setJRFTPIFormData}
                      JRFTPIFormData={JRFTPIFormData}
                      operationStepNo={operationStepNo}
                      setParameterDataTableMain={setParameterDataTableMain}
                    />
                  </Col>
                </Row>
              ))}
            </div>
          ) : null
        ) : section.type === "SFMTabs" ? (
          tabOpen ? (
            <div key={"form-section" + sectionIndex}>
              <div className="nav nav-tabs nav-pills nav-fill">
                {testMemoSetData.map((tab, tabIndex) => {
                  return (
                    <React.Fragment key={"tabIndex" + tabIndex}>
                      <NavItem key={"section-tab" + tabIndex}>
                        <NavLink
                          className={classnames("nav-link tab_header", {
                            active: activeTab === `${sectionIndex}-${tabIndex}`,
                          })}
                          onClick={() =>
                            setActiveTab(`${sectionIndex}-${tabIndex}`)
                          }
                          tabIndex="0"
                          href="#"
                        >
                          {"Set " + (tabIndex + 1)}
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="tab-content">
                {testMemoSetData.map((tab, tabIndex) => (
                  <div
                    key={"tab-content" + tabIndex}
                    role="tabpanel"
                    className={classnames("tab-pane", {
                      active: activeTab === `${sectionIndex}-${tabIndex}`,
                    })}
                  >
                    <Row>
                      <Col>
                        <RenderAdvSFMTableSection
                          key={`${sectionIndex}-${tabIndex}`}
                          section={section}
                          tabIndex={tabIndex}
                          setData={tab}
                          sectionIndex={sectionIndex}
                          formData={formData}
                          handleFieldChange={handleFieldChange}
                          formErrors={formErrors}
                          addRow={() => {
                            true
                              ? addRow(tab, sectionIndex)
                              : setShowModal(true);
                          }}
                          addColumn={() => addColumn(tab, sectionIndex)}
                          deleteRow={() => deleteRow(sectionIndex)}
                          deleteColumn={(columnIndex) =>
                            deleteColumn(sectionIndex, columnIndex)
                          }
                          groupAssignment={tab.groupAssignment}
                          handleAllSave={handleAllSave}
                          handleCancel={handleCancel}
                          gaData={gaData}
                          setGaData={setGaData}
                          showModalGA={showModalGA}
                          setShowModalGA={setShowModalGA}
                          pageType={pageType}
                          actionClicked={actionClicked}
                          setFormData={setFormData}
                          viewOnly={viewOnly}
                          activeTab={activeTab}
                          allFormulaList={allFormulaList}
                          moduleType={moduleType}
                          OperationTypeID={OperationTypeID}
                          EditRecordId={EditRecordId}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>

              <div className="tab_footer">
                <p>
                  {rolesDetails.map((role, UserIndex) => (
                    <span key={"role-" + UserIndex}>
                      {formData[0].user_role === role?.role ? role.label : null}
                    </span>
                  ))}{" "}
                  <br />
                  <br /> <span>{formData[0].sfm_updated_by}</span>
                </p>
                <p>
                  {rolesDetails.map((role, UserIndex) => (
                    <span key={"role-" + UserIndex}>
                      {formData[0]?.technical_manager?.role === role?.role
                        ? role.label
                        : null}
                    </span>
                  ))}{" "}
                  <br />
                  <br /> <span>{formData[0]?.technical_manager?.name}</span>
                </p>
              </div>
            </div>
          ) : null
        ) : section.type === "JIcertificate" ? (
          tabOpenSecond ? (
            <div key={"form-section" + sectionIndex}>
              <div className="nav nav-tabs nav-pills nav-fill">
                {testMemoSetData.map((tab, tabIndex) => {
                  return (
                    <React.Fragment key={"tabIndex" + tabIndex}>
                      <NavItem key={"section-tab" + tabIndex}>
                        <NavLink
                          className={classnames("nav-link tab_header", {
                            active: activeTab === `${sectionIndex}-${tabIndex}`,
                          })}
                          onClick={() =>
                            setActiveTab(`${sectionIndex}-${tabIndex}`)
                          }
                          tabIndex="0"
                          href="#"
                        >
                          {"Set " + (tabIndex + 1)}
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="tab-content">
                {section.tabs.map((tab, tabIndex) => (
                  <Row key={"tabIndx" + tabIndex}>
                    <Col>
                      <RenderTableSectionCertificate
                        key={`${sectionIndex}-${tabIndex}`}
                        section={tab}
                        sectionIndex={sectionIndex}
                        formData={formData}
                        handleFieldChange={handleFieldChange}
                        formErrors={formErrors}
                        addRow={() => {
                          tab.headers.length < 6 ||
                          pageType === "inward" ||
                          pageType === "assignment"
                            ? addRow(tab, sectionIndex)
                            : setShowModal(true);
                        }}
                        deleteRow={() => deleteRow(sectionIndex)}
                        deleteColumn={(columnIndex) =>
                          deleteColumn(sectionIndex, columnIndex)
                        }
                        setFormData={setFormData}
                        popupMessages={formConfig.popupMessages}
                        pageType={pageType}
                        action={action}
                        masterOptions={masterResponse}
                        actionClicked={actionClicked}
                        setSaveClicked={setSaveClicked}
                        saveClicked={saveClicked}
                        setTableData={setSubTableData}
                        tableData={subTableData}
                        moduleType={moduleType}
                        pageType2={true}
                        simpleInwardResponse={simpleInwardResponse}
                        setSimpleInwardResponse={setSimpleInwardResponse}
                        groupDefaultValue={groupDefaultValue}
                        testMemoId={testMemoId}
                        getVerificationDetails={getVerificationDetails}
                        getSampleIdsMasterData={getSampleIdsMasterData}
                        getAssignmentMasterData={getAssignmentMasterData}
                        isDisplayNewAddOption={isDisplayNewAddOption}
                        setIsDisplayNewAddOption={setIsDisplayNewAddOption}
                        setIsOverlayLoader={setIsOverlayLoader}
                        isOverlayLoader={isOverlayLoader}
                        useForComponent={useForComponent}
                        OperationTypeID={OperationTypeID}
                        TMLID={TMLID}
                        OperationType={OperationType}
                        operationName={operationName}
                        editReordType={editReordType}
                        setJRFTPIFormData={setJRFTPIFormData}
                        JRFTPIFormData={JRFTPIFormData}
                        operationStepNo={operationStepNo}
                      />
                    </Col>
                  </Row>
                ))}
              </div>

              {/* <div className="tab_footer">
                <p>
                  {rolesDetails.map((role, UserIndex) => (
                    <span key={"role-" + UserIndex}>
                      {formData[0].user_role === role?.role ? role.label : null}
                    </span>
                  ))}{" "}
                  <br />
                  <br /> <span>{formData[0].sfm_updated_by}</span>
                </p>
                <p>
                  {rolesDetails.map((role, UserIndex) => (
                    <span key={"role-" + UserIndex}>
                      {formData[0]?.technical_manager?.role === role?.role
                        ? role.label
                        : null}
                    </span>
                  ))}{" "}
                  <br />
                  <br /> <span>{formData[0]?.technical_manager?.name}</span>
                </p>
              </div> */}
            </div>
          ) : null
        ) : section.type === "list" ? null : (
          <div
            key={"form-config-section" + sectionIndex}
            className="my-2 bg-white"
          >
            <Card className="section_card">
              <CardBody
                className={
                  "section_card_body " +
                  (moduleSubType === "vesselList" &&
                    "section_card_body_vesselList")
                }
              >
                <CardTitle tag="h5" className="section_title">
                  <div className="list_breadcrumb">
                    {sectionIndex === 0 &&
                      formConfig?.breadcom?.map((title, i) => (
                        <div key={"Form-breadcom" + i}>
                          {i === 0 ? null : (
                            <i className="bi bi-chevron-right card-title-icon"></i>
                          )}{" "}
                          <button
                            className="breadcrumb_button"
                            type="button"
                            onClick={() => navigate(title?.redirect)}
                          >
                            {title.title}
                          </button>
                        </div>
                      ))}
                  </div>
                </CardTitle>

                <div className="card_header_btns">
                  {tileHeader?.map((tile, tileIndex) => (
                    <button
                      type="button"
                      className={getTileClassName(
                        formData[0]?.["ji_internal_status"],
                        tile
                      )}
                    >
                      <div>
                        <p>{tile.leftSubTitle}</p>
                        <p className="extra-text">{tile.rightSubTitle}</p>
                      </div>
                      <span className="extra-text">{tile.Text}</span>
                    </button>
                  ))}
                </div>

                <Row className="main_form">
                  {section.fields.map((field, fieldIndex) => {
                    let isShow = true;
                    let isViewOnly = viewOnly;
                    if (!isViewOnly && field.fieldName == "msfm_number") {
                      isShow = false;
                    }
                    if (
                      (field.name === "jrf_remark" &&
                        formData[0]?.jrf_status !== "rejected") ||
                      (field.name === "jrf_status" &&
                        !formData[0]?.jrf_status) ||
                      (field.name === "tm_remarks" &&
                        formData[0]?.status !== "rejected") ||
                      // (field.name === "ic_borometric_pressure" &&
                      //   ["C", "L"].includes(formData[0]?.company_code)) ||
                      // (field.name === "sfm_borometricpressure" &&
                      //   ["C", "L"].includes(formData[0]?.company_code))
                      (field.name === "ic_borometric_pressure" &&
                        GetTenantDetails(1, 1) != "TPBPL") ||
                      (field.name === "sfm_borometricpressure" &&
                        GetTenantDetails(1, 1) != "TPBPL")
                    ) {
                      isShow = false;
                    }
                    if (moduleType === "testmemomain") {
                      if (
                        (field.name == "tm_datestartinganalysis" ||
                          field.name == "tm_datecompletion") &&
                        !["results", "certified", "verified"].includes(
                          formData[0]?.["status"]
                        )
                      ) {
                        isShow = false;
                      } else if (field.name == "sample_condition") {
                        if (GetTenantDetails(1, 1) == "TPBPL") {
                          isShow = false;
                        }
                      }
                    } else if (
                      moduleType === "sampleinward" ||
                      moduleType === "inwardChecklist"
                    ) {
                      if (
                        (field.name == "smpl_detail_dos" ||
                          field.name == "smpl_detail_recpt_mode") &&
                        !formData[1]?.sampleInwardIdMain
                      ) {
                        isViewOnly = false;
                      } else if (
                        field.name === "jrf_branch_name" &&
                        formData[0]?.jrf_is_external
                      ) {
                        isShow = false;
                      }
                    } else if (moduleType === "jrf") {
                      if (
                        field.name == "fk_sub_commodity" &&
                        !isRefrenceNoCalled
                      ) {
                        isShow = false;
                      }
                    } else if (moduleType === "internalcertificate") {
                      if (
                        field.name == "ic_rejection_remark" &&
                        !["tm-reject", "dtm-reject"].includes(
                          formData[0].status
                        )
                      ) {
                        isShow = false;
                      } else if (field.name === "ic_is_size_analysis") {
                        if (isViewOnly) {
                          isShow = false;
                        }
                      }
                    } else if (moduleType === "sfm") {
                      if (
                        field.name == "sfm_remarks" &&
                        formData[0].sfm_status !== "rejected"
                      ) {
                        isShow = false;
                      }
                    } else if (moduleType === "jobinstruction") {
                      if (
                        field.name == "ji_dispatch_address" &&
                        formData[0].ji_is_ecertification !== "Print Hard Copy"
                      ) {
                        isShow = false;
                      } else if (field.name === "ji_with_whom") {
                        if (
                          !formData[0].ji_type_of_sampling ||
                          ["Independently"].includes(
                            formData[0].ji_type_of_sampling
                          )
                        ) {
                          isShow = false;
                        }
                      }
                    }
                    if (
                      [
                        "allotment_msfm_number",
                        "ic_msfm_number",
                        "jrf_msfm_number",
                        "inward_msfm_number",
                        "inward_msfm_number",
                        "tm_msfm_no",
                      ].includes(field.name)
                    ) {
                      if (
                        !["BU"].includes(user?.role) &&
                        !user?.logged_in_user_info?.lab_or_branch
                          ?.lab_is_compliant
                      ) {
                        isShow = false;
                      }
                    }

                    return (
                      isShow && (
                        <div
                          key={"Form-Extra-Adjustments" + fieldIndex}
                          className={"col-md-" + field.width}
                        >
                          <RenderFields
                            field={getCustomCellValues(field)}
                            sectionIndex={sectionIndex}
                            fieldIndex={fieldIndex}
                            formData={formData}
                            handleFieldChange={handleFieldChange}
                            formErrors={formErrors}
                            ////For Group Assignment Only....
                            gaData={gaData}
                            setGaData={setGaData}
                            showModalGA={showModalGA}
                            setShowModalGA={setShowModalGA}
                            ////For Group Assignment Only....
                            ////For Master Data....
                            masterOptions={masterResponse}
                            ////For Master Data....
                            ////For View Status.....
                            viewOnly={isViewOnly}
                            actionClicked={actionClicked}
                          />
                          {!isValideReferenceNo &&
                          field.name === "jrf_referenceno" ? (
                            <p className="text-danger errorMsg">
                              {
                                "Please enter valid reference no for getting commodity"
                              }
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )
                    );
                  })}
                  <PopupGA
                    showModalGA={showModalGA}
                    setShowModalGA={setShowModalGA}
                    staticData={section.fields}
                  />
                </Row>
                {moduleSubType !== "vesselList" && (
                  <div className="submitBtn_container">
                    {pageType === "inward" &&
                      !formData[1]?.sampleInwardIdMain && (
                        <button
                          type="button"
                          onClick={(e) =>
                            handleInwardMainSubmit(
                              e,
                              formData,
                              setSaveClicked,
                              setTabOpen,
                              setFormData,
                              setIsOverlayLoader
                            )
                          }
                          className="submitBtn"
                        >
                          Proceed
                        </button>
                      )}
                    {moduleType === "sampleverification" &&
                      !istavSaveClicked && (
                        <button
                          type="button"
                          onClick={() => handleSampleVerificationMainSubmit()}
                          className="submitBtn"
                        >
                          {translate("common.saveBtn")}
                        </button>
                      )}
                    {moduleType === "sfm" && !viewOnly && !istavSaveClicked && (
                      <button
                        type="button"
                        onClick={(e) => handleSFMMainSubmit()}
                        className="submitBtn"
                        // disabled={saveClicked}
                      >
                        {translate("common.saveBtn")}
                      </button>
                    )}
                    {/* {moduleType ===
                    "jobinstruction" &&
                    // !editModuleId &&
                    (
                      <button
                        type="button"
                        className="submitBtn"
                        onClick={() => handleJIValidation(
                          handleSubmit,
                          setJrfCreationType,
                          setIsPopupOpen,
                          "save"
                        )}
                      >
                        {translate("common.saveBtn")}
                      </button>
                    )} */}
                    {viewDetail && (
                      <JIPopupModal
                        section={section}
                        sectionIndex={sectionIndex}
                        formData={formData}
                        handleFieldChange={handleFieldChange}
                        formErrors={formErrors}
                        setViewDetail={setViewDetail}
                        setIsOverlayLoader={setIsOverlayLoader}
                        isOverlayLoader={isOverlayLoader}
                      />
                    )}
                    {moduleType === "jobinstruction" && sectionIndex === 0 && formData[0]?.ji_id && (
                      <button
                        type="button"
                        className="searchby_button View_Details "
                        onClick={() => setViewDetail(true)}
                      >
                        View Full <br />
                        Job Details
                      </button>
                    )}
                    {moduleType === "jobinstruction" && sectionIndex === 1 && (
                      <div className="operationCertificateBtns">
                        <button
                          type="button"
                          className="saveBtn View_Details "
                          onClick={() => setViewDetail(true)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="saveBtn View_Details "
                          onClick={() => handleCertificateSave()}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        )
      )}
      {moduleType === "GroupAssignment" ? (
        <GroupAssignmentButtons
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          subTableData={subTableData}
          formData={formData}
          jrfId={jrfId}
          handleBackButtonFunction={handleBackButtonFunction}
          isDisplayNewAddOption={isDisplayNewAddOption}
        />
      ) : moduleType === "groupAssignmentPreview" ? (
        <GroupAssignmentPreviewButtons
          formData={formData}
          jrfId={jrfId}
          handleBackButtonFunction={handleBackButtonFunction}
        />
      ) : moduleType === "testmemomain" ? (
        <TestMemoButtons
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          viewOnly={viewOnly}
          role={user?.role}
          setIsRejectPopupOpen={setIsRejectPopupOpen}
          testMemoId={testMemoId}
          pageType={pageType}
          handleBackButtonFunction={handleBackButtonFunction}
          setIsOverlayLoader={setIsOverlayLoader}
        />
      ) : moduleType === "sampleverification" ? (
        istavSaveClicked && (
          <SampleVerificationButtons
            setIsPopupOpen={setIsPopupOpen}
            setJRFCreationType={setJrfCreationType}
            handleSubmit={handleSubmit}
            saveClicked={saveClicked}
            tableData={subTableData}
            formData={formData}
            viewOnly={viewOnly}
            handleBackButtonFunction={handleBackButtonFunction}
            setSaveClicked={setSaveClicked}
          />
        )
      ) : moduleType === "allotment" ? (
        <AllotmentButtons
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          saveClicked={saveClicked}
          handleAllotValidate={handleAllotValidate}
          viewOnly={viewOnly}
          handleBackButtonFunction={handleBackButtonFunction}
          setIsOverlayLoader={setIsOverlayLoader}
        />
      ) : moduleType === "sampleinward" ? (
        <SampleInwardButtons
          action={action}
          tabOpen={tabOpen}
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          formData={formData}
          subTableData={subTableData}
          jrfId={jrfId}
          viewOnly={viewOnly}
          handleBackButtonFunction={handleBackButtonFunction}
        />
      ) : moduleType === "internalcertificate" ? (
        <InternalCertificateButtons
          action={action}
          tabOpen={tabOpen}
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          formData={formData}
          subTableData={subTableData}
          jrfId={jrfId}
          viewOnly={viewOnly}
          handleSubmit={handleSubmit}
          remarkText={remarkText}
          setSaveClicked={setSaveClicked}
          formConfig={formConfig}
          saveClicked={saveClicked}
          setIsRejectPopupOpen={setIsRejectPopupOpen}
          handleBackButtonFunction={handleBackButtonFunction}
          setIsOverlayLoader={setIsOverlayLoader}
          isValideValue={isValideValue}
        />
      ) : moduleType === "inwardChecklist" ? (
        <ViewCheckListButtons
          remarkText={remarkText}
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          setInwardBtnchange={setInwardBtnchange}
          formData={formData}
          setSaveClicked={setSaveClicked}
          formConfig={formConfig}
          saveClicked={saveClicked}
          setIsRejectPopupOpen={setIsRejectPopupOpen}
          viewOnly={viewOnly}
          handleBackButtonFunction={handleBackButtonFunction}
          setIsOverlayLoader={setIsOverlayLoader}
        />
      ) : moduleType === "sfm" ? (
        istavSaveClicked && (
          <SFMButtons
            setIsPopupOpen={setIsPopupOpen}
            setJRFCreationType={setJrfCreationType}
            handleSubmit={handleSubmit}
            saveClicked={saveClicked}
            formData={formData}
            viewOnly={viewOnly}
            testMemoSetData={testMemoSetData}
            handleBackButtonFunction={handleBackButtonFunction}
          />
        )
      ) : moduleType === "jobinstruction" ? (
        <JIButtons
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          handleSubmit={handleSubmit}
          viewOnly={viewOnly}
          handleBackButtonFunction={handleBackButtonFunction}
          action={action}
          tabOpen={tabOpen}
          setInwardBtnchange={setInwardBtnchange}
          formData={formData}
          subTableData={subTableData}
          EditRecordId={EditRecordId ? EditRecordId : formData[0]?.ji_id}
          editReordType={editReordType}
          navigate={navigate}
          setJrfCreationType={setJrfCreationType}
          formConfig={formConfig}
          setIsRejectPopupOpen={setIsRejectPopupOpen}
          setIsOverlayLoader={setIsOverlayLoader}
          useForComponent={useForComponent}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          testMemoSetData={testMemoSetData}
          isDisplayNewAddOption={isDisplayNewAddOption}
          isViewOnlyTable={isViewOnlyTable}
          operationStepNo={operationStepNo}
          OperationType={OperationType}
          OperationTypeID={OperationTypeID}
        />
      ) : moduleType === "jrf" ? (
        <JRFButtons
          setIsPopupOpen={setIsPopupOpen}
          setJRFCreationType={setJrfCreationType}
          handleSubmit={handleSubmit}
          viewOnly={viewOnly}
          handleBackButtonFunction={handleBackButtonFunction}
        />
      ) : null}
      {isPopupOpen && openDeletePopup()}
      {isRejectPopupOpen && openRejectModal()}

      {formConfig.listView ? (
        <Card>
          <CardBody className="list_body">
            <CardTitle tag="h5" className="list_title">
              <div className="list_breadcrumb">
                {formConfig?.breadcom?.map((title, i) => (
                  <span key={"form-breadcom-main-" + i}>
                    {i === 0 ? null : (
                      <i
                        className="bi bi-chevron-right card-title-icon"
                        key={"form-breadcom-icon" + i}
                      ></i>
                    )}{" "}
                    {title.title}
                  </span>
                ))}
              </div>

              {listModuleType !== "confirugationCertificate" && (
                <div className="jrf_container">
                  <div className="jrf_container_btns">
                    <div className="formSearch">
                      <i className="bi bi-search"></i>
                      <input
                        type="search"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => searchAPI(e.target.value)}
                      />
                    </div>

                    <button
                      type="button"
                      className="searchby_button"
                      onClick={() => setSearchby(!searchby)}
                    >
                      {/* <img src={SearchIcon}></img> */}
                      <i class="bi bi-search"></i>
                      {translate("common.searchReportBtn")}
                    </button>

                    {isFilterBtnClicked && (
                      <>
                        <button
                          type="button"
                          className="searchby_button"
                          onClick={() => clearFilterData()}
                        >
                          Clear Filter
                        </button>
                        {(["sampleinward", "sampleverification"].includes(
                          listModuleType
                        ) ||
                          (listModuleType === "allotment" &&
                            CommonTMRoles.includes(user?.role))) && (
                          <button
                            type="button"
                            className="searchby_button"
                            onClick={() => getAllListingDataExports()}
                          >
                            Export
                          </button>
                        )}
                      </>
                    )}
                    {["jrf"].includes(listModuleType) &&
                    isModuelePermission(
                      rolePermissions,
                      listModuleType,
                      "add"
                    ) ? (
                      <button
                        type="button"
                        onClick={() =>
                          navigate(
                            user?.role === "LR"
                              ? "/jrfListing/external-jrf"
                              : "/jrfListing/jrf"
                          )
                        }
                        className="create_button"
                      >
                        <i className="bi bi-plus-lg"></i>
                        {translate("common.createBtn")}
                      </button>
                    ) : null}
                    {["jobinstruction"].includes(listModuleType) &&
                    user?.role == "OPS ADMIN" &&
                    isModuelePermission(
                      rolePermissions,
                      listModuleType,
                      "add"
                    ) ? (
                      <button
                        type="button"
                        onClick={() =>
                          navigate(
                            "/operation/jrfInstructionListing/job-instruction"
                          )
                        }
                        className="create_button"
                      >
                        <i className="bi bi-plus-lg"></i>
                        {translate("common.createBtn")}
                      </button>
                    ) : null}
                  </div>

                  {searchby && (
                    <PopupSearch
                      setSearchby={setSearchby}
                      searchConfigJson={searchConfigJson}
                      filterIndex={filterIndex}
                      handleSearchFilter={handleSearchFilter}
                      searchFormData={searchFormData}
                      searchFormDataType={searchFormDataType}
                      setFilterIndex={setFilterIndex}
                      setSearchFormDataErros={setSearchFormDataErros}
                      searchFormDataErros={searchFormDataErros}
                      setSearchFormData={setSearchFormData}
                      getAllListingData={getAllListingData}
                      setisFilterBtnclicked={setIsFilterBtnClicked}
                      user={user}
                    />
                  )}
                </div>
              )}
            </CardTitle>
            {isModuelePermission(rolePermissions, listModuleType, "view") && (
              <div className="ag-theme-quartz" id="myGrid">
                {" "}
                <RenderListSection
                  section={formConfig.listView}
                  sectionIndex={1}
                  actions={formConfig.listView.actions}
                  responseData={response}
                  getAllListingData={getAllListingData}
                  formConfig={formConfig}
                  statusCounts={statusCounts}
                  setIsRejectPopupOpen={setIsRejectPopupOpen}
                  setJRFCreationType={setJrfCreationType}
                  setIsPopupOpen={setIsPopupOpen}
                  loadingTable={loadingTable}
                  setIsOverlayLoader={setIsOverlayLoader}
                  kpiValue={kpiValue}
                  setKpiValue={setKpiValue}
                />
              </div>
            )}
          </CardBody>
        </Card>
      ) : null}
    </form>
  ) : (
    <Loading />
  );
};

Forms.propTypes = {
  formConfig: PropTypes.object,
  masterResponse: PropTypes.object,
  getSampleIdsMasterData: PropTypes.func,
  searchConfigJson: PropTypes.object,
  getAssignmentMasterData: PropTypes.func,
  setTestMemoId: PropTypes.func,
  testMemoId: PropTypes.string,
  testReportPrweview: PropTypes.any, // Adjust based on the actual type or shape
  isExternalJRF: PropTypes.bool,
  totalSamples: PropTypes.number,
  setMasterResponse: PropTypes.func,
  useForComponent: PropTypes.string,
  isViewOnlyTable: PropTypes.string,
};

export default Forms;
