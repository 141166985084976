import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const InputCheckbox = ({ field }) => {
  const {
    name,
    label,
    error,
    tooltip,
    characterLimit,
    options,
    onChange,
    required,
    viewOnly,
    value,
    actionClicked,
    isOptionLabelNotShow,
    fieldWidth,
  } = field;
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChange = (e) => {
    setIsChecked((prev) => !prev);

    onChange(e);
  };

  const oGHandleChange = (e, option) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedOptions((prev) => {
        const updatedOptions = [...prev, option];
        onChange(e, updatedOptions);

        return updatedOptions;
      });
    } else {
      setSelectedOptions((prev) => {
        const updatedOptions = Array.isArray(prev) ? prev.filter((item) => item !== option) : [];
        onChange(e, updatedOptions);
        return updatedOptions;
      });

    }
  };
  useEffect(() => {
    setSelectedOptions(value);
    setIsChecked(value);
  }, [value]);

  return (
    <div className="form-group my-2 listform">
      {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className={"w-" + (fieldWidth ?? "125") + " mx-2 radioOptions"}>
        {options?.map((option, optionIndex) => (
          <span className="radioOption" key={optionIndex + "Checkbox"}>
            {name === "jrf_terms_and_conditions" ? (
              <input
                type="checkbox"
                id={name}
                name={name}
                title={tooltip}
                maxLength={characterLimit}
                required={required}
                value={isChecked}
                checked={viewOnly ? true : isChecked}
                disabled={viewOnly}
                onChange={handleChange}
              />
            ) : (
              <input
                type="checkbox"
                id={name}
                name={name}
                title={tooltip}
                maxLength={characterLimit}
                required={required}
                value={selectedOptions}
                checked={selectedOptions.includes(option)}
                onChange={(e) => oGHandleChange(e, option)}
                disabled={viewOnly}
              />
            )}
            {!isOptionLabelNotShow && (
              <label className="checkbox-text" htmlFor={name}>
                {option}{" "}
                {name === "jrf_terms_and_conditions" && (
                  <span className="boldClass">
                    <br />
                    <br />
                    We hereby accept the terms and conditions mentioned above.
                  </span>
                )}
              </label>
            )}
          </span>
        ))}
        {!isChecked && actionClicked && name === "jrf_terms_and_conditions" && (
          <p className="text-danger errorMsg">
            {"Please accept the terms and conditions."}
          </p>
        )}
        {error && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

InputCheckbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    tooltip: PropTypes.string,
    characterLimit: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string, // Adjust based on actual structure
        label: PropTypes.string  // Adjust based on actual structure
      })
    ),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    viewOnly: PropTypes.bool,
    value: PropTypes.any, // Use specific type if known, e.g., PropTypes.string, PropTypes.number
    actionClicked: PropTypes.func,
    isOptionLabelNotShow: PropTypes.bool,
    fieldWidth: PropTypes.string // Adjust if fieldWidth is a different type
  })
};
export default InputCheckbox;
