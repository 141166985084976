import React, { useState } from "react";
import RenderFields from "./RenderFields";
import { Row } from "react-bootstrap";
import viewJIDetails from "../../formJsonData/Operations/jobinstructions/viewJIDetails.json"
const JIPopupModal = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  formErrors,
  setViewDetail,
  setIsOverlayLoader,
  isOverlayLoader
}) => {
  const getCustomCellValues = (cell) => {
    return cell;
  };
  return (
    <div className="popupSearchContainerBG">
      <div className="popupInwardModal  JIPopupModal">
        <div className="rejectSearchCross">
          <button
            onClick={() => setViewDetail(false)}
            className="nonNativeButton2"
            aria-label="Reject Button"
          >
            <i className="bi bi-x-lg h4"></i>
          </button>
        </div>
        <div className="JIPopupModalContainer">
        <Row className="main_form">
          {viewJIDetails?.map((field, fieldIndex) => (
            <div
              className={"col-md-" + field.width}
              key={"Headers-" + fieldIndex}
            >
              <RenderFields
                field={getCustomCellValues(field)}
                sectionIndex={sectionIndex}
                fieldIndex={fieldIndex}
                formData={formData}
                handleFieldChange={handleFieldChange}
                formErrors={formErrors}
                viewOnly={true}
              />
            </div>
          ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default JIPopupModal;
